@import '../../variables.scss';
@import '../../mixins.scss';

.formulario {
    display: grid;
    gap: 50px;

    &__form {
        display: grid;
        gap: 30px;
    }

    &__two_columns {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px 30px;
    }

    &__card {
        display: flex;
        flex-direction: column;
        gap: 8px;

        label {
            @include font-size(18);
            font-family: 'Courier New', Courier, monospace;
        }

        input,
        select {
            border: none;
            height: 40px;
            line-height: 40px;
            border-radius: 4px;
            box-shadow: 0 0 4px #cfcfcf;
            padding: 0 15px;
            @include font-size(18);

            &:focus {
                outline: none;
            }
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type='number'] {
           
            text-align: center;
        }

        input[type='checkbox'] {
            height: inherit;
            line-height: inherit;
            border-radius: 0;
            box-shadow: inherit;
            padding: 0;
            @include font-size(16);
            scale: 1.5;
        }

        select {
           
        }

        textarea {
            resize: none;
            border: none;
            height: 120px;
            border: none;
            border-radius: 4px;
            box-shadow: 0 0 4px #cfcfcf;
            padding: 10px 15px;
            @include font-size(18);

            &:focus {
                outline: none;
            }
        }

        &_invalid {
            border: 1px solid red !important;
        }
    }

    &__tags {
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(4, 1fr);
        
        &_label {
            display: flex;
            place-items: center;
            gap: 20px;
            width: fit-content;
        }
    }

    &__radio {
        label {
            @include font-size(18);
            font-family: 'Courier New', Courier, monospace;
            display: block;
        }

        &_grid {
            display: flex;
            gap: 15px;
            margin-top: 10px;
        }

        &_input {
            display: flex;
            place-items: center;

            label {
                padding-left: 10px;
            }
        }
    }

    small {
        @include font-size(14);
        color: $danger-color;
        font-weight: bold;
    }

    &__imagem {
        display: grid;
        gap: 10px;

        label {
            @include font-size(18);
            font-family: 'Courier New', Courier, monospace;
        }

        input {
            height: 40px;
            line-height: 40px;
            border: none;
            border-radius: 4px;
            @include font-size(18);
        }

        &_prev {
            max-width: 100%;
        }
    }

    button {
        background-color: $info-color;
        color: $light-color;
        border: none;
        border-radius: 4px;
        padding: 0 20px;
        @include font-size(18);
        height: 40px;
        line-height: 40px;
       

        &:hover {
            background-color: darken($color: $info-color, $amount: 20);
        }
    }

    &__conteudo {
        label {
            display: block;
            margin-bottom: 10px;
            @include font-size(18);
            font-family: 'Courier New', Courier, monospace;
        }
    }
}
