.container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 70vh;
    background-color: #031028;
}

.sub_container {
    width: 80%;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 35px;
}

.title {
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    color: white;
    font-size: 30px;
}
.subtitle,
.secondary_title {
    color: white;
    font-weight: 400 !important;
    font-family: 'Nunito', sans-serif;
    font-size: 20px;
    width: 47%;
}
.secondary_title {
    font-weight: 700;
}
.description {
    color: white;
    font-size: 22px;
    width: 50%;
}

.button:link,
.button:visited {
    text-transform: uppercase;
    text-decoration: none;
    padding: 12px 60px 12px 35px;
    display: inline-block;
    border-radius: 100px;
    transition: all 0.2s;
    position: absolute;
}

.button:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.button:active {
    transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.button {
    width: 160px;
    height: 50px;
    border-radius: 40px;
    background-color: #48b485;
    color: white;
    font-size: 18px;
    font-family: 'Nunito', sans-serif;
    font-weight: bolder;
    border: none;
    position: relative;
}

.button::after {
    content: '';
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 100px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all 0.4s;
}

.button::after {
    background-color: #48b485;
}

.button:hover::after {
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0;
}

.button {
    margin-top: 320px;
    display: flex;
    animation: moveInBottom 5s ease-out;
    animation-fill-mode: backwards;
}

@keyframes moveInBottom {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}
.ctn_image {
    display: flex;
    align-items: center;
    position: relative;
    @media (max-width: 900px) {
        align-items: start;
        padding-top: 20px;
    }
    > img {
        width: 65%;
        z-index: 2;
    }
}
.fundo {
    position: absolute;
    width: 50% !important;
    right: 43%;
    object-fit: contain;
    z-index: 1;
}
