@import '../../variables.scss';
@import '../../mixins.scss';

.sobre {
    display: grid;
    grid-template-columns: 1fr;
    background-color:  #0F1628;
    color: #fff;

    @media (min-width:1024px){
        padding-top: 0;
    }

    &__text {
        padding: 0 8% 20px 8%;
        text-align: center;
    }

    &__text p {
        margin: 15px 0;
    }
}

.sobre__text > .paragrafos {
    margin: auto;
}

.sobre_titulo > h3 {
    color:aliceblue;
    font-family: 'Nunito serif', sans-serif;
    justify-content: center;
    font-weight:700;
    text-align: center;
    font-size: 30px;
    margin: 20px 0;

    @media (min-width:768px){
        font-size: 36px;
    }

    @media (min-width:1024px){
    }
}


.paragrafos > span  {
    font-family: 'Nunito sans', sans-serif;
    font-weight:bold;
    color:$primary-color;
}

.paragrafos {
    font-size: 14px;
    text-align: justify;

    @media (min-width:1024px){
        color:white;
        font-size: 20px;
    }
    @media (min-width:1900px){
        font-size: 30px;
    }
}
