@import url('https://fonts.googleapis.com/css2?family=Borel&family=Nunito+Sans:opsz@6..12&family=Nunito:wght@400;700&family=REM:ital,wght@1,300&family=Roboto:wght@400;500;700&display=swap');

.section,
.section__desktop {
    margin-top: 30px;
    display: grid;
    justify-content: space-around;
    align-items: center;
}
.section {
    background-color: #0f1628;
}
.section__desktop {
    background-color: #031028;
}

.container {
    margin-top: 18px;
    border-radius: 60px;
    display: grid;
    grid-template-columns: 1fr;
    margin: auto;
    justify-content: space-between;
    background-color: #fff;
    text-align: center;
    gap: 10px;
    margin-bottom: 20px;

    @media (min-width: 1024px) {
        grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 1024px) {
        margin-bottom: 20px;
    }
}

.container div {
    @media (min-width: 320px) {
        border-bottom: 2px solid #c9bfbf;
    }

    @media (min-width: 768px) {
        border-bottom: 2px solid #c9bfbf;
        display: grid;
        align-content: space-between;
        padding: 0 10px;
    }
    @media (min-width: 1024px) {
        border-bottom: none;
        border-right: 2px solid #c9bfbf;
    }
}

.container__desktop {
    display: flex;
    gap: 90px;
    justify-content: space-around;
    width: 90vw;
    padding: 15px;
    height: 400px;
}

.cards__desktop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    > img {
        width: 8vw;
    }
    > p {
        font-size: 40px;
        color: white;
        font-family: 'Nunito', sans-serif;
        font-weight: 700;
    }
    > h2 {
        color: white;
        font-family: 'Nunito', sans-serif;
        width: 65%;
        text-align: center;
    }
}

.container div:last-child {
    @media (min-width: 320px) {
        border-bottom: none;
    }
    border-right: none;
}

.cards > h2 {
    padding: 15px;
    font-size: 18px;
    color: #56bb8f;
    font-family: 'Nunito', sans-serif;
    @media (min-width: 1024px) {
        font-size: 22px;
    }
}

.cards > p {
    font-size: 45px;
    color: #56bb8f;
    font-family: 'Nunito', sans-serif;
    font-weight: bolder;
}

.cards > img {
    margin: auto;
    height: 100px;
    width: 240px;
}
.cards {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: space-between;
    gap: 10px;
    padding: 20px;
    opacity: 0.9;

    @media (min-width: 1024px) {
        padding: 30px;
        margin: 20px;
    }
}
.cards:last-child {
    @media (min-width: 1024px) {
        margin-left: -20px;
    }
}
