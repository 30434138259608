@import '../../../variables.scss';
@import '../../../mixins.scss';

.estatistica {
    padding: 10px $page-gap-mobile 40px $page-gap-mobile;

    @media (min-width: 1900px) {
        padding: 10px $page-gap 40px $page-gap;
    }

    &__numeros {
        display: flex;
        flex-direction: column;
        gap: 20px;

        @media (min-width: 768px) {
            flex-direction: row;
            justify-content: space-between;
        }

        @media (min-width: 1024px) {
            @include font-size(24);
        }

        @media (min-width: 1440px) {
            @include font-size(28);
        }

        & span {
            @include font-size(32);
            font-weight: bold;

            @media (min-width: 1024px) {
                @include font-size(40);
            }
        }

        & div {
            display: flex;
            flex-direction: column;
            text-align: center;
        }
    }
}
