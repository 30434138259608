@import '../../../variables.scss';
@import '../../../mixins.scss';

.contato__info_privacidade {
    color: white;
    font-size: 17px;
}
.footer {
    padding: 10px $page-gap-mobile;
    background-color: #0f1628;
    color: #fff;
    display: grid;
    grid-template-columns: 1fr 2fr;
    justify-content: space-between;

    @media (min-width: 768px) {
        padding: 0 $page-gap-mobile;
        flex-direction: row;
        min-height: 90px;
    }

    @media (min-width: 1900px) {
        padding: 20px $page-gap;
    }

    & img {
        padding: 7px;
        width: 70px;
        margin-right: 30px;

        @media (min-width: 768px) {
            width: 110px;
            margin-right: 0px;
        }

        @media (min-width: 1024px) {
            width: 150px;
        }
    }
}

.texto {
    font-size: 12px;
    justify-self: end;
    align-self: center;
    @media (min-width: 768px) {
        font-size: 14px;
    }

    @media (min-width: 1024px) {
        font-size: 22px;
    }
}
