.card {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: -1px -2px 2px 3px #14171ec9;
    opacity: 0.9;
    height: 460px;
    padding: 15px;

    @media (min-width:1024px){
        height: 370px;

    }
    @media (min-width:1440px){
        height: 400px;

    }
    @media (min-width:1900px){
        height: 480px;
        margin: 15px;
    }
    &:hover{
        opacity: 1.0;
        background-image: linear-gradient(45deg,rgba(17, 233, 150, 0.214),rgba(17, 233, 150, 0.211));
        transition: 3s;
        cursor:pointer;
    }
}

.media > img {
    margin: auto;
    height: 150px;
    width: 230px;
    @media (min-width:1024px){
        width: 200px;
        height: 140px;
        padding: 5px;
    }
    @media (min-width:1440px){
        padding: 0px;
        width: 250px;
        height: 180px;
    }
}

.media {
    display: grid;
}

.text > h2 {
    display: grid;
    font-size: 24px;
    color:#56BB8F;
}

.text > p {
    text-align: center;
    color:#0f0707;
    font-size: 20px;
    @media (min-width:1024px){
      font-size: 14px;
    }
    @media (min-width:1440px){
        font-size: 18px;
      }
      @media (min-width:1900px){
        font-size: 22px;
      }
}

.titulo {
    color:#56BB8F;
}

.titulo > p {
    font-weight: 600;
    font-size: 22px;
    text-align: center;
    @media (min-width:1024px){
        font-size:16px;
        margin-bottom: 20px;
      }
    @media (min-width:1440px){
        margin-bottom: 0px;
        font-size: 18px;
    @media (min-width:1900px){
        font-size: 24px;
        margin-bottom: 20px;
      }
     }
}
