@import '../../../variables.scss';
@import '../../../mixins.scss';

.contato {
    padding: 20px $page-gap-mobile;
    display: grid;
    gap: 30px;

    @media (min-width: 1024px) {
        grid-template-columns: 500px 1fr;
    }

    @media (min-width: 1900px) {
        padding: 30px $page-gap;
    }

    & p {
        font-size: 12px;

        @media (min-width: 768px) {
            font-size: 14px;
        }

        @media (min-width: 1024px) {
            font-size: 16px;
        }

        @media (min-width: 1900px) {
            font-size: 20px;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        gap: 10px;

        @media (min-width: 768px) {
            place-content: center;
        }

        & p {
            display: flex;
            gap: 10px;
        }

        &_privacidade {
            color: #0c170f;
        }
    }

    &__midia {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 20px;

        @media (min-width: 1024px) {
            margin-top: 10px;
        }

        & h3 {
            margin-bottom: 20px;
            font-family: 'Nunito', sans-serif;
            color: $secundary-color;
            text-align: start;
            font-size: 30px;
            text-transform: uppercase;
        }
    }

    &__redes {
        display: flex;
        justify-content: center;
        gap: 15px;
    }
}
.contato__desktop {
    padding: 20px $page-gap-mobile;
    display: grid;
    gap: 30px;
    height: 220px;

    @media (min-width: 1024px) {
        grid-template-columns: 500px 1fr;
    }

    @media (min-width: 1900px) {
        padding: 30px $page-gap;
    }

    & p {
        font-size: 12px;
        font-family: 'Nunito', sans-serif;

        @media (min-width: 768px) {
            font-size: 14px;
        }

        @media (min-width: 1024px) {
            font-size: 16px;
        }

        @media (min-width: 1900px) {
            font-size: 20px;
        }
    }

    &__info__desktop {
        display: flex;

        gap: 10px;

        @media (min-width: 768px) {
            place-content: center;
        }

        & p {
            display: flex;
            gap: 10px;
        }

        &_privacidade {
            color: #0c170f;
        }
    }

    &__midia {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 20px;

        @media (min-width: 1024px) {
            margin-top: 10px;
        }

        & h3 {
            margin-bottom: 20px;
            font-family: 'Nunito', sans-serif;
            color: $secundary-color;
            text-align: start;
            font-size: 30px;
            text-transform: uppercase;
        }
    }

    &__redes {
        display: flex;
        justify-content: center;
        gap: 15px;
    }
}

.img_contato {
    width: 35px;
}

.contato_privacidade a:hover {
    color: #0e9e21;
}
