@import url('https://fonts.googleapis.com/css2?family=Borel&family=Nunito:wght@600&family=REM:ital,wght@1,300&display=swap');
@import '../../../variables.scss';
@import '../../../mixins.scss';

.headline_desktop {
    padding: 20px 13.5% !important;
    background-color: #ffff;
    display: grid;
    gap: 20px;
    margin-bottom: 20px;

    @media (min-width: 1024px) {
        grid-template-columns: minmax(400px, 1fr) 1fr;
    }
    @media (min-width: 1440px) {
        grid-template-columns: minmax(550px, 1fr) 625px;
    }

    @media (min-width: 1900px) {
        padding: 20px $page-gap;
    }

    &__video {
        @media (min-width: 1024px) {
            order: 2;
            align-self: center;
        }
    }

    &__text__desktop {
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: space-between;
        padding: 25px 0px;
        color: #262626;
        > p {
            font-family: 'Nunito', sans-serif;
            font-weight: 400;
            font-size: 24px;
            width: 80%;
        }
        @media (min-width: 1024px) {
            grid-column: 1;
        }
    }
}

.headline {
    padding: 20px $page-gap-mobile;
    background-color: #ffff;
    display: grid;
    gap: 20px;
    margin-bottom: 20px;

    @media (min-width: 1024px) {
        grid-template-columns: minmax(400px, 1fr) 1fr;
    }
    @media (min-width: 1440px) {
        grid-template-columns: minmax(550px, 1fr) 625px;
    }

    @media (min-width: 1900px) {
        padding: 20px $page-gap;
    }

    &__video {
        @media (max-width: 768px) {
            max-width: 80vw;
        }
        @media (min-width: 1024px) {
            order: 2;
            align-self: center;
        }
    }

    &__text {
        display: flex;
        gap: 10px;
        flex-direction: column;
        color: $white-color;
        &__iframe {
            @media (min-width: 1024px) {
                grid-column: 2;
            }
            & a > button {
                font-size: 14px;
                @media (min-width: 768px) {
                    font-size: 22px;
                }

                iframe {
                    width: 100%;
                    height: 400px;
                    border: none;

                    @media (min-width: 1024px) {
                    }
                }
            }

            & p {
                display: inline;
                font-size: 18px;
                color: $primary-color;
            }

            & h1 {
                font-size: 20px;
                font-family: 'Nunito', sans-serif;
                color: #0f1628;
                text-align: left;
                letter-spacing: 1.5px;
                @media (min-width: 320px) {
                    width: 100%;
                    font-size: 16px !important;
                }

                @media (min-width: 768px) {
                    font-size: 26px;
                    width: 100%;
                }

                @media (min-width: 1900px) {
                    font-size: 32px;
                }
            }

            & h4 {
                font-family: 'Nunito', sans-serif;
                font-size: 20px;
                color: #5f6368;
                font-weight: 400;
                text-align: start;
                @media (min-width: 300px) {
                    font-size: 14px !important;
                }

                @media (min-width: 768px) {
                    font-size: 24px;
                }

                @media (min-width: 1024px) {
                    font-size: 24px;
                }

                @media (min-width: 1900px) {
                    font-size: 33px;
                }
            }

            @media (min-width: 1024px) {
                padding: 14px;
            }

            @media (min-width: 1900px) {
                font-size: 30px;
            }
        }

        & button {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            align-items: center;
            padding: 10px;
            border: none;
            background-color: #0f1628;
            font-size: 18px;
            font-weight: bold;
            border-radius: 10px;
            opacity: 1;
            color: rgb(235, 225, 225);
            margin: auto;
            margin-top: 15px;

            @media (min-width: 768px) {
                text-align: normal;
                border: 1px solid $dark-color;
                transition: 0.2s ease-in-out;
            }

            @media (min-width: 1024px) {
                padding: 12px;
                margin: auto;
                margin-top: 10px;
            }

            &:hover {
                background-color: $dark-color;
                opacity: 0.9;
                transition: 0.5s;
            }

            @media (min-width: 1900px) {
                line-height: 50px;
                display: flex;
                height: 65px;
                justify-content: center;
                width: 450px;
                margin-top: 20px;
            }
        }
    }
}

.destaque {
    font-family: 'Nunito', sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: $primary-color;
    @media (min-width: 300px) {
        font-size: 14px;
    }

    @media (min-width: 1024px) {
        font-size: 24px;
    }

    @media (min-width: 1900px) {
        font-size: 30px;
    }
}
.headline > .headeline__text {
    @media (min-width: 320px) {
        order: 1;
    }
}
.headline > .headline__video {
    @media (min-width: 320px) {
        order: 2;
    }
}

.headline__text > h1 {
    font-size: 24px;
    font-family: 'Nunito', sans-serif;

    @media (min-width: 1440px) {
        font-size: 34px;
    }

    @media (min-width: 1024px) {
        font-size: 26px;
    }
    @media (min-width: 1900px) {
        font-size: 38px;
    }
}

.destaque_button {
    color: #56bb8f;
    font-family: 'Nunito serif', sans-serif;
    margin: 4px;
}

.ctn_title {
    display: flex;
    > p {
        font-family: 'Nunito', sans-serif;
        font-weight: 600;
        font-size: 30px;
        color: #000000;
    }
    width: 74%;
    margin: 50px auto 40px auto;
}

.container_footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    margin: 30px auto 50px auto;
}
.container_child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 60%;
}

.sub_child {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    min-height: 196px;
    > p {
        display: flex;
        width: 75%;
        font-family: 'Nunito', sans-serif;
        font-weight: 400;
        font-size: 24px;
    }
}
