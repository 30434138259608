.card {
    display:grid;
    gap: 15px;
    grid-template-columns: 1fr;
    justify-content: space-around;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 20px 30px 10px #a2a3aa30;
    opacity: 0.9;
    background-color:white;
    margin-bottom:30px;

    @media (min-width:1024px){
    width: 225px;
    height: 350px;
        padding-bottom: 20px;;
    }
    @media (min-width:1440px){
        width: 350px;
       }
    @media (min-width:1900px){
        width: 430px;
        height: 400px;
        gap: 10px;
    }


}
.card:hover {
    opacity: 1.0;
    background-image: linear-gradient(45deg,rgba(17, 233, 150, 0.313),rgba(17, 233, 150, 0.278));
    cursor: pointer;
}

.media > img {
    display: grid;
    margin: auto;
    padding: 5px;
    height: 140px;
    @media (min-width:768px){
     height: 110px;
    }
    @media (min-width:1024px){
        height: 120px;
       }
    @media (min-width:1440px){
     height: 160px;
    }
    @media (min-width:1900px){
       height: 200px;
    }
}

.text > h2 {
    font-size: 24px;
    color:#56BB8F;

}

.text > p {
    text-align: center;
    color: rgba(0, 0, 0, 0.82);
    font-weight: 500;
    font-size: 16px;
    padding-top: 15px;
    @media (min-width:1900px){
        font-size: 18px;
     }

}

.titulo{
    color:#56BB8F;
}

.titulo > p {
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    @media (min-width:1900px){
        font-size: 22px;
     }
}

