@import '../../variables.scss';
@import '../../mixins.scss';

.servicos {
    padding: 0 45px;
    text-align: center;
    margin-bottom: 40px;
    display: grid;
    grid-template-columns: 1fr;
   

    @media (min-width:1024px) {
        display: grid;
        gap: 10px;
        margin-bottom:30px;
    }

    @media (min-width: 1900px) {
        padding: 0 $page-gap;
    }

    &__items {
        display: grid;
        gap: 30px;

        @media (min-width: 768px) {
            margin:auto;
            gap: 70px;
            grid-template-columns: 1fr 1fr;
        }

        @media (min-width: 1024px) {
            display: flex;
            grid-template-columns: 1fr 1fr 1fr;
            gap:80px;
            
        }
        @media (min-width:1900px){
            margin: auto;
        }
    }

    &__infos {
        display: flex;
        flex-direction: column;
        gap: 15px;

        & h2 {
            text-transform: uppercase;
            text-align: center;
            color: $secundary-color;
        }

        & p {
            text-align: justify;
        }

        & a {
            border-radius: 5px;
            background-color: $primary-color;
            border: 1px solid $primary-color;
            font-size: 18px;
            color: $white-color;
            text-align: center;
            padding: 5px 0;
        }

        & iframe {
            @media (min-width: 768px) {
                height: -webkit-fill-available;
                min-height: 300px;
            }
        }
    }
}

.decoration {
    font-size: 20px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    @include font-size(14);
    margin-bottom: 10px;

    @media (min-width: 768px) {
        margin-bottom: 15px;
    }
}

.titulo {
    color: #5f6368;
    display: inline;
    text-align:center;
}


.destaque_titulo {
    font-family: 'Nunito serif', sans-serif;
    color: #56BB8F;
   
}

.titulo > p {
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    width: 100%;
    font-size: 22px;
    margin:auto;
    margin-top: 20px;
    margin-bottom: 25px;
    @media (min-width:768px){
       
    }
    @media (min-width:1024px){
        text-align: center;
        font-size: 34px;
    }
}