@import '../../variables.scss';
@import '../../mixins.scss';

.login {
    background-image: url('../../assets/images/background_login.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    width: 100%;
    height: 100vh;
    position: relative;

    &__card {
        @include center(both, fixed);
        background-color: rgba(0, 0, 0, 0.1);
        padding: 8px;
        box-shadow: 0 0 30px #434343;
       
    }

    &__box {
        background-color: rgba(0, 0, 0, 0.5);
        padding: 20px 35px;
    }

    &__title {
        text-align: center;
        margin-bottom: 30px;
        color: $light-color;
        text-transform: uppercase;
        letter-spacing: 0.5px;
    }
}

.form {
    display: grid;
    gap: 20px;

    &__box {
        position: relative;
    }

    & input {
        background-color: $white-color;
        height: 40px;
        line-height: 40px;
        border: none;
        width: 100%;
        padding-left: 50px;

        &:focus {
            outline: none;
        }
    }

    & span {
        @include position(absolute, left 0 bottom 0 top 0);
        background-color: #e8e8e8;
       
        display: flex;
        place-content: center;
    }

    & img {
       
    }

    & button {
        height: 40px;
        line-height: 40px;
        text-transform: uppercase;
        background-color: $primary-color;
        border: none;
        letter-spacing: 0.5px;
        font-weight: 700;
        @include font-size(18);
    }

    &__invalid {
        color: red;
        padding: 5px 10px;
        text-align: center;
        font-size: 18px;
        line-height: 18px;
        font-weight: bold;
        letter-spacing: 0.3px;
    }
}
