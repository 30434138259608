.container__desktop {
    display: grid;
    grid-template-columns: 1fr;
    background-color: #031028;
    gap: 10px;
    height: 400px;
}
.container {
    display: grid;
    grid-template-columns: 1fr;
    background-color: #0f1628;
    gap: 10px;
}

.conteudo {
    display: grid;
    padding: 20px;
    margin: 3%;
    border-radius: 80px;
    background-color: white;

    @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
        gap: 10px;
        width: 70%;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}
.conteudo__desktop {
    display: grid;
    padding: 20px;
    margin: 3%;
    border-radius: 80px;

    @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
        gap: 10px;
        width: 70%;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

.conteudo > div:last-child {
    border-top: 1px solid grey;
    @media (min-width: 768px) {
        border-top: none;
        border-right: none;
        padding-left: 25px;
    }
}

.conteudo > div:first-child {
    @media (min-width: 768px) {
        margin-right: -25px;
        padding-right: 35px;
        gap: 15px;
        border-right: 1px solid grey;
    }
}

.section > h2 {
    font-family: 'Nunito', sans-serif;
    font-weight: bolder;
    text-align: center;
    color: #56bb8f;
}

.section {
    display: grid;
    margin: auto;
    text-align: center;
}
.section__desktop {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    text-align: center;
    gap: 5px;
    > #titulo_right {
        width: 100%;
    }
    > h2 {
        width: 75%;
    }

    > p {
        font-size: 30px;
    }
    > h2,
    p {
        color: white;
        font-family: 'Nunito', sans-serif;
    }
}

.dados {
    font-family: 'Nunito', sans-serif;
    font-weight: bolder;
    color: #56bb8f;
    font-size: 35px;
    text-align: center;

    @media (min-width: 1024px) {
        font-size: 45px;
    }
}

.imagem {
    height: 80px;
    @media (min-width: 1024px) {
        height: 120px;
    }
}
