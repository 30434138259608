@import '../../../variables.scss';
@import '../../../mixins.scss';

.card {
    padding: 10px 15px;
    border-radius: 10px;
    box-shadow: -5px -5px 3px 5px #56bb8f;
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;

    @media (min-width: 768px) {
        gap: 10px;
        padding: 10px 0px;
    }

    @media (min-width: 1024px) {
        display: grid;
        padding: 10px;
    }
}
