.section {
    display: grid;
    margin-top: 10px;
    justify-content: center;
    grid-template-columns: 1fr;
    font-size: 12px;
    @media (min-width: 1024px) {
        font-size: 16px;
    }
}

.titulo {
    padding: 28px;
    text-align: center;
    color: #56bb8f;
    @media (min-width: 1900px) {
        font-size: 38px;
    }
}

.paragrafos {
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    margin: auto;
    max-width: 60%;
    text-align: justify;
    color: #5f6368;

    @media (min-width: 1024px) {
        font-size: 22px;
    }
    @media (min-width: 1900px) {
        font-size: 28px;
    }
}

.destaque {
    color: #56bb8f;
    font-family: 'Nunito serif', sans-serif;
    font-weight: bold;
}

.button {
    box-shadow: 0px 5px 20px 1px #0c081c78;
    font-size: 18px;
    opacity: 0.9;
    background-color: #0f1628;
    align-items: center;
    font-family: 'Nunito', sans-serif;
    border-radius: 10px;
    font-weight: 600;
    justify-content: center;
    padding: 10px;
    text-align: center;
    margin: auto;
    margin-top: 20px;

    @media (min-width: 768px) {
        padding: 20px;
        width: none;
    }
}
.button:hover {
    opacity: 1;
}
