.container {
    background-color: #f5f5f5;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    min-height: 200px;
    padding-bottom: 10px;
    z-index: 2;
    @media (max-width: 700px) {
        height: 220px;
        padding-bottom: 30px;
    }
}

.imagem {
    width: 90px;
    @media (max-width: 700px) {
        width: 75px;
        margin-top: -30px;
    }
    @media (min-width: 705px) and (max-width: 900px) {
        margin-top: -40px;
    }
}

.texto {
    z-index: 2;
    text-align: center;
    width: 75%;
    margin-top: 60px;
    @media (max-width: 1000px) {
        margin-top: 90px;
    }
    > h2 {
        color: #000000;
        font-family: 'Nunito', sans-serif;
        font-weight: 600;
        @media (max-width: 1000px) {
            font-size: 18px;
            margin-bottom: 10px;
        }
    }

    > p {
        font-family: 'Nunito', sans-serif;
        font-weight: normal;
        margin: 0 auto;
        font-size: 20px;
        text-align: center;
    }
    @media (max-width: 700px) {
        width: 80%;
    }
}

.fundo {
    background-color: #f5f5f5;
    z-index: 1;
    position: absolute;
    height: 200px;
    display: flex;
    align-items: start;
    padding-top: 25px;
    justify-content: center;
    width: 200px;
    top: -37%;
    border-radius: 200px;
    @media (max-width: 1000px) {
        top: -25%;
        width: 150px;
    }
    @media (max-width: 700px) {
        width: 175px;
    }
}
