@import '../../../mixins.scss';
@import '../../../variables.scss';

.action {
    display: grid;
    gap: 25px 50px;
    padding: 20px;
    background-color:$dark-color;
    color: $white-color;

    @media(min-width:768px){
        grid-template-columns: 1fr 1fr;
    }

    @media (min-width:1024px) {
        padding: 20px $page-gap-mobile;
        grid-template-columns: 1fr 400px;
    }

    @media (min-width:1900px) {
        padding: 20px 20%;
    }

    &__sender {
        position: relative;
        height: fit-content;
        align-self: center;

        &_icon {
            @include position(absolute, left 2px top 6.5px);
        }
    }

    h3 {
        font-size: 22px;
        font-weight: normal;
        font-family:'Nunito serif',sans-serif;

        @media(min-width:768px){
            font-size: 22px;
        }

        @media (min-width:1440px) {
            @include font-size(36);
        }

        @media (min-width:1024px){
            @include font-size(32)
        }
    }

    p {
        font-size: 13px;
        @media (min-width:768px) {
            font-size:15px;
        }
        @media (min-width:1440px) {
            @include font-size(22);
        }
    }

    input {
        line-height: 35px;
        border-radius: 4px;
        border: none;
        padding-left: 30px;
        padding-right: 90px;
        @media (min-width:425px){
        padding-right: 120px;
        }

        @media (min-width:1024px){
            padding-right: 150px;
        }
        @media (min-width:1440px){
            padding-right: 170px;
        }


        &:focus {
            outline: none;
        }
    }

    button {
        @include position(absolute, right 0 top 0 bottom 0);
        background-color: $dark-color;
        border: none;
        display: flex;
        place-items: center;
        gap: 8px;
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
        justify-content: center;
        @media (min-width:1024px){

        }
    }
}

.mensagem {
    text-align: center;

    h2 {
        font-size: 32px;
        letter-spacing: 0.7px;
        font-weight: 500;
        margin-bottom: 20px;
    }

    p {
        @include font-size(18);
        margin-bottom: 10px;
    }
}

.erro {
    h2,
    p {
        color: red;
    }
}

.sucesso {
    h2,
    p {
        color: green;
    }
}

.action__sender > button {
    background-color: $primary-color;
    font-size: 16px;
    padding-right:2px;
}


.destaque {
    color:white;
}

.active > button > a {
    @media (min-width:768px) {
       margin-left: 60px;
    }
}

.button_valid {
    display: flex;
    padding-left: 5px;
}