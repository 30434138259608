@mixin font-size($size, $base: 16) {
    font-size: ($size / $base) * 1rem;
}

@mixin screen($size) {
    $desktopX: '(min-width: 1900px)';
    $desktopLG: '(min-width: 1440px)';
    $desktop: '(min-width: 1024px)';
    $tablet: '(min-width: 768px)';
    $mobile: '(min-width: 420px)';

    @if $size == desktop {
        @media only screen and #{$desktop} {
            @content;
        }
    } @else if $size == tablet {
        @media only screen and #{$tablet} {
            @content;
        }
    } @else if $size == mobile {
        @media only screen and #{$mobile} {
            @content;
        }
    } @else if $size == desktopLG {
        @media only screen and #{$desktopLG} {
            @content;
        }
    } @else if $size == desktopX {
        @media only screen and #{$desktopX} {
            @content;
        }
    }
}

@mixin position($position, $args) {
    @each $o in top right bottom left {
        $i: index($args, $o);
        @if $i and $i + 1 <= length($args) and type-of(nth($args, $i + 1)) == number {
            #{$o}: nth($args, $i + 1);
        }
    }

    position: $position;
}

// Positioning helpers
@mixin absolute($args: '') {
    @include position(absolute, $args);
}

@mixin fixed($args: '') {
    @include position(fixed, $args);
}

@mixin relative($args: '') {
    @include position(relative, $args);
}

@mixin center($eixo, $position: absolute) {
    @if $eixo == vertical {
        top: 50%;
        transform: translateY(-50%);
    } @else if $eixo == horizontal {
        left: 50%;
        transform: translateX(-50%);
    } @else if $eixo == both {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    position: $position;
}
