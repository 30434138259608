@import '../../../variables.scss';
@import '../../../mixins.scss';

.video {
    border: 1px solid rgba(0, 0, 0, 0.7);
    border-radius: 4px;
    margin-bottom: 15px;

    h3 {
        background-color: rgba(0, 0, 0, 0.7);
        padding: 10px 15px;
        @include font-size(18);
        color: $light-color;
        letter-spacing: 0.5px;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        font-weight: bold;
    }

    strong {
        color: $text-dark;
    }

    &__info {
        display: flex;
        gap: 15px 50px;
        padding: 15px;
        flex-wrap: wrap;
    }

    &__detalhes {
        display: flex;
        gap: 15px;
        justify-content: space-between;
    }

    &__descricao {
        padding: 0 15px 15px 15px;
        display: grid;
        gap: 10px;
    }

    &__acoes {
        padding: 15px;
        display: flex;
        gap: 15px;

        button {
            height: 35px;
            line-height: 35px;
            padding: 0 20px;
            border: none;
            border-radius: 4px;
            font-weight: 500;
            text-transform: uppercase;

            &:nth-child(1) {
                background-color: $success-color;

                &:hover {
                    background-color: darken($color: $success-color, $amount: 20);
                }
            }

            &:nth-child(2) {
                background-color: $danger-color;

                &:hover {
                    background-color: darken($color: $danger-color, $amount: 20);
                }
            }
        }
    }
}
