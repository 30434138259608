@import '../../../variables.scss';
@import '../../../mixins.scss';

.infos {
    background-color: $gray-header-color;
    height: 40px;
    line-height: 40px;
    padding: 0 $page-gap-mobile;
    display: flex;
    justify-content: center;
    font-size: 14px;
    position: relative;
    z-index: 999;

    @media (min-width: 768px) {
        justify-content: space-between;
    }

    @media (min-width: 1900px) {
        padding: 0 $page-gap;
    }

    & img {
        height: 22px;
    }

    & a {
        text-decoration: none;
        display: flex;
        align-items: center;
    }
}

.sistemas {
    background-color: transparent;
    border: none;
    color: #fff;
    text-transform: uppercase;
    font-family: 'Nunito', sans-serif;
    font-size: 18px;
    text-align: center;
    font-weight: 700;
    min-width: 75px;
    max-width: 120px;
    &:hover {
        cursor: pointer;
        color: darken($color: $primary-color, $amount: 15);
    }

    &:focus {
        outline: none;
    }
    > option {
        color: #111;
        font-size: 18px;
        text-transform: initial;
        border: 1px solid white;
    }
}
.icons {
    fill: white;
    background-color: white;
}

.contato {
    display: none;
    gap: 15px;
    color: $white-color;

    @media (min-width: 768px) {
        display: flex;
    }

    & span {
        display: flex;
        gap: 5px;
        align-items: center;
    }
}

.menu > nav > p {
    color: white;
    font-weight: 400;
}

.menu > nav > button {
    color: white;
    background-color: #0f1628;

    @media (min-width: 768px) {
        margin-left: 30px;
        background-color: #0f1628;
        border-radius: 20px;
    }
    @media (min-width: 1024px) {
        width: 100%;
        padding: 10px;
        margin-left: 40px;
    }
}

.menu__desktop {
    padding: 0 $page-gap-mobile;
    background-color: $primary-color;
    height: 60px;
    display: flex;
    justify-content: space-between;
    position: relative;
    place-items: center;
    z-index: 999;
    @media (min-width: 768px) {
        height: 80px;
    }
    @media (min-width: 1900px) {
        padding: 0 $page-gap;
    }

    &__icon {
        background-color: transparent;
        color: white;

        & img {
            width: fit-content;
        }
        @media (min-width: 769px) {
            display: none;
        }
    }

    & nav {
        @include position(absolute, top 100% right 0 left 0);
        font-size: 14px;
        display: grid;
        background-color: $primary-color;
        height: 0;
        transition: 0.2s ease-in-out;
        opacity: 0;
        pointer-events: none;
        cursor: default;

        @media (min-width: 769px) {
            display: flex;
            height: 40px;
            line-height: 40px;
            align-self: center;
            align-items: center;
            position: unset;
            width: unset;
            opacity: 1;
            pointer-events: inherit;
            cursor: inherit;
        }

        @media (min-width: 1024px) {
            @include font-size(16);
        }

        &.active {
            height: 350px;
            opacity: 1;
            pointer-events: inherit;
            cursor: inherit;
        }

        & a {
            margin-left: 2px;
            text-decoration: none;
            font-weight: 600;
            color: #fff;
            border-width: 1px;
            letter-spacing: 0.5px;
            transition: 0.2s ease-in-out;
            padding: 0 $page-gap-mobile;
            line-height: 50px;
            height: 50px;
            border-bottom: 1px solid white;

            @media (min-width: 769px) {
                padding: 0 10px;
                height: unset;
                border-bottom: none;
                text-transform: uppercase;
                font-size: 18px;
                font-weight: 700;
                font-family: 'Nunito', sans-serif;
            }
        }

        & a:last-child {
            background-color: #0f1628;
            border: none;
            @media (min-width: 769px) {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 15px;
                border-radius: 50px;
                width: 225px;
                min-width: 90px;
                height: 55px;
                display: inline-block;
                color: #fff;
                text-transform: uppercase;
                font-size: 1rem;
                transition: all 0.3s;
                position: relative;
                overflow: hidden;
                z-index: 1;
                text-align: center;
                padding-top: 4px;
                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: #0f1628;
                    border-radius: 10rem;
                    z-index: -2;
                }
                &:before {
                    content: 'MINUS';
                    color: #0f1628;
                    font-family: 'Nunito', sans-serif;
                    font-weight: 800;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 0%;
                    height: 100%;
                    background-color: white;
                    transition: all 0.3s;
                    z-index: 1;
                }
                &:hover {
                    color: white;
                    &:before {
                        width: 100%;
                    }
                }
            }
        }
    }

    & a:hover {
        color: darken($color: $primary-color, $amount: 15);
        border: none;
    }
}
.menu {
    padding: 0 $page-gap-mobile;
    background-color: $primary-color;
    height: 60px;
    display: flex;
    justify-content: space-between;
    position: relative;
    place-items: center;
    z-index: 999;

    @media (min-width: 1900px) {
        padding: 0 $page-gap;
    }

    &__icon {
        border: none;
        background-color: transparent;

        @media (min-width: 769px) {
            display: none;
        }

        & img {
            width: fit-content;
        }
    }

    & nav {
        @include position(absolute, top 100% right 0 left 0);
        font-size: 14px;
        display: grid;
        background-color: $primary-color;
        height: 0;
        transition: 0.2s ease-in-out;
        opacity: 0;
        pointer-events: none;
        cursor: default;

        @media (min-width: 769px) {
            display: flex;
            height: 40px;
            line-height: 40px;
            align-self: center;
            align-items: center;
            position: unset;
            width: unset;
            opacity: 1;
            pointer-events: inherit;
            cursor: inherit;
        }

        @media (min-width: 1024px) {
            @include font-size(16);
        }

        &.active {
            height: 350px;
            opacity: 1;
            pointer-events: inherit;
            cursor: inherit;
        }

        & a {
            margin-left: 2px;
            text-decoration: none;
            font-weight: 600;
            color: #fff;
            border-width: 1px;
            letter-spacing: 0.5px;
            transition: 0.2s ease-in-out;
            padding: 0 $page-gap-mobile;
            line-height: 50px;
            height: 50px;
            border-bottom: 1px solid white;

            @media (min-width: 769px) {
                padding: 0 10px;
                line-height: unset;
                height: unset;
                border-bottom: none;
                text-transform: uppercase;
                font-size: 18px;
                font-weight: 700;
                font-family: 'Nunito', sans-serif;
            }
        }

        & a:last-child {
            background-color: #0f1628;
            border-radius: 10px;
            border: none;

            @media (min-width: 769px) {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 20px;
                border-radius: 50px;
                padding: 8px 16px;
                width: 233px;
                min-width: 100px;
                height: 78px;
            }
        }
    }

    & a:hover {
        color: darken($color: $primary-color, $amount: 15);
        border: none;
    }
}

.sticky {
    @include position(fixed, left 0 right 0 top 0);
}

.overlay {
    @include position(fixed, left 0 right 0 top 0 bottom 0);
    background-color: rgba(0, 0, 0, 0.45);
    z-index: 100;
}

.midia {
    display: flex;
    gap: 15px;
}

.logo {
    width: 10vw;
    min-width: 100px;
}
