.container {
    display: grid;
    background-color: #0F1628;
    grid-template-columns: 1fr;
}

.titulo > h1 {
    font-family: 'Nunito serif', sans-serif;
    font-weight:700;
    font-size: 30px;
    margin: 10px;
    text-align: center;
    color:#56BB8F;

    @media(min-width:768px){
        font-size: 35px;
        display: grid;
        margin:20px;
        margin-left: 40px;
        margin-bottom: 20px;
    }
    @media (min-width:1024px){
        font-size: 40px;
    }
}
.cardGrid{
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
    margin: 20px;
    @media(min-width:768px){
        margin: auto;
        margin-top: 50px;
        text-align: center;
        margin-bottom: 30px;
    }
    @media (min-width:1024px){
        grid-template-columns: 1fr 1fr 1fr 1fr;
        margin-top: 60px;
        gap: 20px;
        margin: 10px;
        
    }
}