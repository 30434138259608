@import '../../variables.scss';
@import '../../mixins.scss';

.lista {
    display: grid;
    gap: 30px;

    &__header {
        display: flex;
        justify-content: space-between;

        button {
            background-color: $info-color;
            color: $light-color;
            border: none;
            border-radius: 4px;
            padding: 0 20px;
            @include font-size(18);
            display: flex;
            place-items: center;
            gap: 5px;

            &:hover {
                background-color: darken($color: $info-color, $amount: 20);
            }
        }
    }

    &__itens {
        display: grid;
        gap: 20px;
        margin-top: 50px;
    }

    &__itens_multiplos {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        margin-top: 50px;
    }

    &__confirmacao {
        @include center(both);
        background-color: #fff;
        padding: 30px;
        box-shadow: 0 0 5px #000;
        border-radius: 5px;
       

        p {
            font-size: 20px;
            margin-bottom: 25px;
        }

        &_buttons {
            display: flex;
            gap: 20px;
            justify-content: space-between;

            button {
                padding: 8px 15px;
                border: none;
                font-size: 18px;
                letter-spacing: 0.5px;
                border-radius: 2px;
            }

            button:nth-child(1) {
                background-color: gray;
            }
            button:nth-child(2) {
                background-color: red;
            }
        }
    }
}
