@import '../../../mixins.scss';
@import '../../../variables.scss';

.depoimento {
    background-color: #fff;
    padding: 10px $page-gap-mobile 40px $page-gap-mobile;

    &__text {
        display: grid;
        gap: 50px;
        text-align: center;
        justify-content: space-between;
        @media (min-width: 768px) {
            display: grid;
            grid-template-columns: 2fr 2fr;
            gap: 90px;
        }

        & img {
            height: 110px;
            justify-self: center;
            border-radius: 50%;
        }

        & p {
            margin: auto;
            color: #5f6368;
            font-size: 16px;
            padding: 8px;
        }

        & span {
            color: $primary-color;
            font-weight: bold;
        }
    }

    &__perfil {
        align-self: end;
    }
}

.depoimento__desktop {
    background-color: #031028;
    padding: 10px $page-gap-mobile 40px $page-gap-mobile;

    @media (min-width: 1900px) {
        padding: 10px $page-gap 40px $page-gap;
    }

    &__text__desktop {
        display: flex;
        gap: 50px;
        text-align: center;
        justify-content: space-between;
        align-items: start;
        padding-top: 45px;

        @media (min-width: 769px) {
            display: grid;
            grid-template-columns: 2fr 2fr;
            gap: 90px;
        }

        & img {
            justify-self: center;
            border-radius: 50%;
        }

        & p {
            margin: auto;
            padding: 8px;
            font-size: 24px !important;
            font-family: 'Nunito', sans-serif;

            @media (min-width: 1900px) {
                font-size: 30px;
            }
        }

        & span {
            font-family: 'Nunito', sans-serif;
            color: #f5f5f5;
            font-weight: 600;
            font-size: 30px;
        }
    }

    &__perfil__desktop {
        align-self: start;
        text-align: start;
        color: #f5f5f5;
        font-weight: 200;
        font-family: 'Nunito', sans-serif;
        font-size: 18px;
    }
}

.ctn_depoimento {
    display: flex;
    justify-content: start;
    padding-right: 100px;
    > p {
        width: 100%;
        font-family: 'Nunito', sans-serif;
        font-weight: 200;
    }
}

.write__desktop {
    color: #f5f5f5;
    text-align: start;
    font-family: 'Nunito', sans-serif;
    font-weight: 200;
}
.ctn_imagem__desktop {
    display: flex;
    align-items: center;
    min-height: 100px;
    gap: 10px;
    margin-bottom: 15px;
    > img {
        width: 95px;
        height: 95px;
        max-width: 100px;
        max-height: 100px;
        border-radius: 100px;
    }
    > a {
        font-style: normal;
    }
}

.titulo {
    color: #56bb8f;
    font-family: 'Nunito serif', sans-serif;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    margin: 40px 0;
    @media (min-width: 1024px) {
        font-size: 40px;
    }
}
