.container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 100px;
    background-color: #d9d9d9;
    padding: 4% 10% 0px 10%;
    margin-bottom: -40px;
}

.cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 110px;
    @media (max-width: 900px) {
        grid-template-columns: 1fr;
    }
}

.titulo {
    color: #031028;
    font-family: 'Nunito', sans-serif;
    font-weight: 800;
    font-size: 30px;
}

.paragrafo {
    font-family: 'Nunito', sans-serif;
    font-size: 24px;
    padding: 20px 0px 20px 0px;
    width: 80%;
}

.btn_contato {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 600px;
    min-width: 250px;
    text-align: center;
    height: 65px;
    margin-bottom: 65px;
    background-color: #031028;
    font-family: 'Nunito', sans-serif;
    font-weight: 900;
    font-size: 20px;
    padding: 12px 24px;
    border: none;
    border-radius: 60px;
    transition: 200ms all ease;
    &:hover {
        transform: scale(1.025);
        background-color: white;
        border-bottom: 2px solid #111;
        color: #000;
    }
}
