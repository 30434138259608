@import '../../variables.scss';
@import '../../mixins.scss';

.title {
    text-align: center;
    margin-top: 25px;
    margin-bottom: 45px;
    color: $secundary-color;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 28px;
}
