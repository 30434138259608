

.container {
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 300px;
    max-width: 100%;


    &p,span {
        font-family: 'Nunito',sans-serif;
    }
}

.titulo {
    font-family: 'Nunito';
    font-weight: bold;
    font-size: 32px;
    color:#56BB8F;
    @media (min-width:1024px){
        font-size:36px;
    }
    @media (min-width:1900px){
        font-size: 50px;
    }
}

.paragrafos {
    display: grid;
    grid-template-columns: 1fr;
    font-size: 16px;
    color: #5F6368;
    @media (min-width:768px) {
        font-size: 22px;
        text-align: center;
        width: 80%;
    }

    @media (min-width:1024px){
        font-size: 26px;
    }
    @media (min-width:1900px){
        font-size: 36px;
    }
}

.destaque {
    font-weight: 400;
    color:#5F6368;
}