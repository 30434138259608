@import '../../variables.scss';
@import '../../mixins.scss';

@mixin font-weight($weight) {
    font-weight: $weight;
}

.servico {
    & h3 {
        color: #56bb8f;
        text-align: center;
        font-size: 36px;
        text-transform: uppercase;

        @media (min-width: 768px) {
            font-size: 42px;
            margin: 0px;
        }

        @media (min-width: 1024px) {
            display: flex;
            font-size: 48px;
            margin: auto;
            justify-content: center;
        }
    }

    & p {
        color: black;
        text-align: center;
        margin-top: 30px;
        font-size: 20px;
        margin-bottom: 10px;
        min-height: 120px;

        @media (min-width: 768px) {
        }
    }

    & button {
        border: 1px solid #0f1628;
        background-color: #0f1628;
        border-radius: 10px;
        align-self: flex-end;
        color: #fff;
        font-family: 'Nunito serif', sans-serif;
        font-size: 16px;
        font-weight: bold;
        margin-top: 10px;
        padding: 10px;

        @media (min-width: 1024px) {
            font-size: 20px;
        }

        @media (min-width: 768px) {
            display: flex;
            margin: auto;
            margin-top: 30px;
            justify-content: end;
            align-items: center;

            font-size: 18px;
        }
    }
}
