@import '../../../variables.scss';
@import '../../../mixins.scss';

@import url('https://fonts.googleapis.com/css2?family=Borel&family=Nunito:wght@600&family=REM:ital,wght@1,300&display=swap');

.clientes {
    background-color: #0f1628;
    padding: 10px $page-gap-mobile 20px $page-gap-mobile;
    @media (min-width: 1900px) {
        padding: 10px $page-gap 40px $page-gap;
    }

    @media (min-width: 768px) {
        width: 100%;
    }

    @media (min-width: 1024px) {
        padding: 5px $page-gap 40px $page-gap;
    }

    & img {
        width: 80%;
        height: 160px;
        margin-left: 5%;

        @media (min-width: 1024px) {
            height: 150px;
        }
    }
}

.container_slide {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

#next_arrow {
    cursor: pointer;
    margin-top: 0px !important;
    @media (max-width: 800px) {
        margin-top: 0px !important;
    }
}

.clientes__items__desktop {
    background-color: #d9d9d9;
    width: 85%;
    display: flex;
    margin: 0 auto;
    min-height: 300px;
    align-items: center;
    justify-content: center;
    @media (max-width: 1740px) {
        width: 80%;
    }
    @media (min-width: 1805px) {
        width: 100%;
    }

    @media (min-width: 1900px) {
        padding: 10px $page-gap 40px $page-gap;
    }

    & img {
        background-color: transparent;
        display: flex;
        width: 80%;
        margin: 0 auto 0px 15px;
        height: 180px;
        object-fit: contain;
    }
}

.logo {
    &_cliente,
    &_panvel,
    &_condor {
        border-radius: 5px;
        box-shadow: 0 0 5px #a8a8a8;
    }

    &_cliente {
        background-color: #fff;
        @media (min-width: 769px) {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 300px;
            width: 180px !important;
            padding: 20px;
        }
    }
}
.logo {
    &_cliente__desktop {
        background-color: #fff;
        @media (max-width: 800px) {
            width: 100px !important;
            height: 150px;
        }
        @media (max-width: 1730px) {
            margin-top: 30px;
            width: 150px !important;
            height: 160px;
        }
        @media (min-width: 1740px) {
            width: 170px !important;
        }
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 300px;
        box-shadow: 0 0 5px #eee;

        > a {
            display: flex;
        }
    }
}

.clientes__desktop {
    background-color: #d9d9d9;
}

.button {
    opacity: 0.9;
    border: none;
    background-color: #56bb8f;
    border-radius: 10px;
    color: white;
    font-size: 20px;
    font-family: 'Nunito serif', sans-serif;
    font-weight: bold;
    padding: 8px 0px;
    display: flex;
    margin: 0 auto;
    margin-top: 20px;
    background-color: red;

    @media (min-width: 769px) {
        display: grid;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 0px;
        padding: 14px;
    }
    @media (min-width: 1024px) {
        margin: auto;
        margin-top: 20px;
    }
}
.button__desktop {
    border: none;
    background-color: #56bb8f;
    border-radius: 100px;
    color: white;
    font-size: 20px;
    font-family: 'Nunito serif', sans-serif;
    font-weight: bold;
    padding: 20px 44px;
    margin-top: 20px;
    display: flex;
    margin: 50px auto 40px auto;
}

.button:hover {
    opacity: 1;
}

.titulo_thispage {
    display: grid;
    color: #ffffff;
    margin: 10px;
}
.titulo_thispage__desktop {
    display: flex;
    align-items: center;
    height: 70px;
    > p {
        color: #031028;
        font-family: 'Nunito', sans-serif;
        font-weight: normal;
        @media (max-width: 900px) {
            font-size: 28px !important;
        }
    }
}

.titulo_thispage > p {
    margin: auto;
    text-align: center;
    font-size: 28px;
    font-family: 'Nunito serif', sans-serif;
}

.titulo_thispage__desktop > p {
    margin: 60px auto 40px auto;
    text-align: center;
    font-size: 38px;
    font-family: 'Nunito serif', sans-serif;
}

.destacado {
    color: #56bb8f;
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
}
