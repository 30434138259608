@import '../../variables.scss';
@import '../../mixins.scss';

.main {
    display: grid;
    gap: 50px;
    margin: 50px 0;

    h1,
    h2 {
        margin-bottom: 15px;
        @include font-size(18);

        @media (min-width:1024px) {
            @include font-size(24);
        }
    }

    p {
        margin-bottom: 5px;
    }

    img {
        width: 100%;
    }

    small {
        display: block;
        @include font-size(14);
        text-align: right;
    }
}

.noticias {
    display: grid;
    gap: 50px;

    @media (min-width:1024px) {
        grid-template-columns: 1fr 1fr;
    }

    @media (min-width:1440px) {
        grid-template-columns: repeat(3, 1fr);
    }

    aside {
        order: 1;

        @media (min-width:1024px) {
            order: 2;
        }
    }

    &__order {
        order: 2;

        @media (min-width:1024px) {
            order: 1;
        }
    }

    h2 {
        @include font-size(28);
        font-weight: normal;
        letter-spacing: 0.5px;
        margin-bottom: 25px;
    }
}

.wrapper {
    margin: 0 30px;
    
    @media (min-width:1024px) {
        margin: 0 $page-gap-mobile;
    }

    @media (min-width:1900px) {
        margin: 0 $page-gap;
    }
}
