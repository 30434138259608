.container_textos {
    padding: 3% 10%;
    > h2,
    p {
        font-family: 'Nunito', sans-serif;
    }
    h2 {
        color: #031028;
        font-weight: 800;
        font-size: 30px;
        margin-bottom: 20px;
    }
    p {
        margin-bottom: 15px;
        font-size: 24px;
        width: 90%;
    }
}
