@import '../../variables.scss';
@import '../../mixins.scss';

.main {
    display: grid;
    gap: 50px;
    margin: 50px 0;

    h1,
    h2 {
        margin-bottom: 15px;
    }

    p {
        margin-bottom: 5px;
    }

    small {
        display: block;
        @include font-size(14);
        text-align: right;
    }
}

.wrapper {
    margin: 0 30px;

    @media (min-width:1024px) {
        margin: 0 $page-gap-mobile;
    }

    @media (min-width:1900px) {
        margin: 0 $page-gap;
    }
}

.autor {
    display: grid;
    gap: 25px;
    margin-top: 50px;
    background-color: $light-color;
    border-radius: 4px;
    padding: 15px 20px;

    @media (min-width:1024px) {
        grid-template-columns: 120px 1fr;
    }

    &__img {
        justify-self: center;
        height: 120px;
        border-radius: 60px;
    }

    &__icon {
       
        margin-top: 15px;
    }

    h4 {
        @include font-size(24);
        letter-spacing: 0.5px;
        font-weight: 500;
        margin-bottom: 8px;

        @media (min-width:1440px) {
            @include font-size(28);
        }
    }
}

.noticia {
    display: grid;
    gap: 30px;

    @media (min-width:1024px) {
        grid-template-columns: 1fr 200px;
    }

    @media (min-width:1440px) {
        grid-template-columns: 1fr 250px;
    }

    img {
        max-width: 100%;
    }

    aside {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    &_hide {
        grid-template-columns: unset;
    }

    h1 {
        @include font-size(32);
        font-weight: 500;
        letter-spacing: 0.5px;

        @media (min-width:1024px) {
            @include font-size(40);
        }
    }

    &__capa {
        margin-top: 10px;
        margin-bottom: 20px;
        width: 100%;
    }

    &__media {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 15px;
        gap: 5px;

        @media (min-width:768px) {
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
        }

        &_icons {
            display: flex;
            gap: 5px
        }
    }
}

.relacionados {
    h2 {
        @include font-size(28);
        font-weight: normal;
        letter-spacing: 0.5px;
        margin-bottom: 25px;
    }

    img {
        width: 100%;
    }

    &__card {
        display: grid;
        gap: 30px;

        @media (min-width:1024px) {
            grid-template-columns: repeat(4, 1fr);
        }
    }
}
