@import url('https://fonts.googleapis.com/css2?family=Borel&family=Nunito:wght@600&family=REM:ital,wght@1,300&display=swap');
@import '../../variables.scss';
@import '../../mixins.scss';

.headline__desktop {
    margin-top: -30px;
    padding: 20px $page-gap-mobile;
    background-color: #ffff;
    display: grid;
    gap: 20px;

    @media (min-width: 768px) {
        padding: 25px $page-gap-mobile;
        grid-template-columns: 1fr;
        justify-content: space-between;
    }

    @media (min-width: 1024px) {
        margin: 25px;
        grid-template-columns: minmax(450px, 1fr) minmax(375px, 600px);

        gap: 25px;
        justify-content: space-between;
    }

    @media (min-width: 1440px) {
        grid-template-columns: minmax(530px, 1fr) minmax(640px, 600px);
        gap: 70px;
    }

    @media (min-width: 1900px) {
        padding: 20px $page-gap;
    }

    &__text__desktop {
        display: flex;
        gap: 20px;
        flex-direction: column;
        color: $white-color;

        @media (min-width: 768px) {
            grid-column: 1;
        }

        &__iframe {
            @media (min-width: 768px) {
                grid-column: 2;
            }

            iframe {
                width: 100%;
                height: 400px;
                border: none;
            }
        }

        & span {
            display: inline;
            font-size: 14px;
            font-family: 'Nunito sans', sans-serif;
            color: $primary-color;

            @media (min-width: 768px) {
                font-size: 18px;
            }
            @media (min-width: 1024px) {
                font-size: 24px;
            }
        }

        & #titulo {
            font-size: 30px;
            font-family: 'Nunito serif', sans-serif;
            font-weight: 700;
            color: #262626;
            margin-bottom: 35px;

            @media (min-width: 1900px) {
                font-size: 36px;
            }
        }

        & .conteudo {
            text-align: left;
            font-family: 'Nunito', sans-serif;
            font-style: normal;
            color: #000;
            font-weight: normal;
            margin-bottom: 0px;
            font-size: 24px !important;
            @media (min-width: 1900px) {
                @include font-size(18);
            }
        }

        & a {
            border: none;
            background-color: $dark-color;
            @include font-size(18);
            font-weight: bold;
            height: 40px;
            line-height: 40px;
            border-radius: 20px;
            margin-top: 30px;
            color: $white-color;
            text-align: center;
            opacity: 0.9;

            @media (min-width: 768px) {
                width: 250px;
                border: 1px solid $dark-color;
                transition: 0.2s ease-in-out;

                &:hover {
                    background-color: $dark-color;
                    opacity: 1;
                }
            }

            @media (min-width: 1024px) {
                line-height: 50px;
                border-radius: 25px;
            }
        }
    }
}

.headline {
    margin-top: -30px;
    padding: 20px $page-gap-mobile;
    background-color: #ffff;
    display: grid;
    gap: 20px;

    @media (min-width: 768px) {
        padding: 25px $page-gap-mobile;
        grid-template-columns: 1fr;
        justify-content: space-between;
    }

    @media (min-width: 1024px) {
        margin: 25px;
        grid-template-columns: minmax(450px, 1fr) minmax(375px, 600px);

        gap: 25px;
        justify-content: space-between;
    }

    @media (min-width: 1440px) {
        grid-template-columns: minmax(530px, 1fr) minmax(640px, 600px);
        gap: 70px;
    }

    @media (min-width: 1900px) {
        padding: 20px $page-gap;
    }

    &__text {
        display: flex;
        gap: 10px;
        flex-direction: column;
        color: $white-color;

        @media (min-width: 768px) {
            grid-column: 1;
        }

        &__iframe {
            @media (min-width: 768px) {
                grid-column: 2;
            }

            iframe {
                width: 100%;
                height: 400px;
                border: none;
            }
        }

        & span {
            display: inline;
            font-size: 14px;
            font-family: 'Nunito sans', sans-serif;
            color: $primary-color;

            @media (min-width: 768px) {
                font-size: 18px;
            }
            @media (min-width: 1024px) {
                font-size: 24px;
            }
        }

        & h1 {
            font-size: 30px;
            font-family: 'Nunito serif', sans-serif;
            color: #56bb8f;
            margin-bottom: 15px;

            @media (min-width: 1900px) {
                font-size: 36px;
            }
        }

        & h4 {
            margin-top: -45px;
            text-align: left;
            font-family: 'Nunito', sans-serif;
            font-style: normal;
            font-size: 18px;
            color: #5f6368;
            margin-bottom: 10px;

            @media (min-width: 320px) {
                order: 1;
            }

            @media (min-width: 768px) {
                @include font-size(27);
            }

            @media (min-width: 1024px) {
                margin-top: -30px;
            }

            @media (min-width: 1900px) {
                @include font-size(18);
            }
        }

        & h3 > a {
            font-size: 28px;
            color: rgb(235, 225, 225);
            padding: 8px;
            margin: auto;
            padding-left: 35px;
            padding-right: 35px;

            @media (min-width: 768px) {
                font-size: 22px;
            }

            @media (min-width: 1900px) {
                font-size: 30px;
            }
        }

        & a {
            border: none;
            background-color: $dark-color;
            @include font-size(18);
            font-weight: bold;
            height: 40px;
            line-height: 40px;
            border-radius: 20px;
            margin-top: 30px;
            color: $white-color;
            text-align: center;
            opacity: 0.9;

            @media (min-width: 768px) {
                width: 250px;
                border: 1px solid $dark-color;
                transition: 0.2s ease-in-out;

                &:hover {
                    background-color: $dark-color;
                    opacity: 1;
                }
            }

            @media (min-width: 1024px) {
                line-height: 50px;
                border-radius: 25px;
            }
        }
    }
}

.headline__text > h1 {
    margin-top: 10px;
    font-family: 'Nunito sans', sans-serif;
    text-align: center;
    font-size: 30px;

    @media (min-width: 768px) {
        font-size: 36px;
    }

    @media (min-width: 1024px) {
        font-size: 50px;
        font-style: bold;
    }
}
.headline__text > h4 {
    font-weight: 600;
    font-size: 14px;
    margin-top: -10px;

    @media (min-width: 320px) {
        order: 1;
    }
    @media (min-width: 768px) {
        font-size: 18px;
    }

    @media (min-width: 1024px) {
        margin-top: -10px;
        font-size: 24px;
        text-align: left;
        letter-spacing: 1.2px;
    }
}

.headline {
    @media (min-width: 320px) {
        display: grid;
        grid-template-columns: 1fr;
    }
}

.headline > .headline__text {
    @media (min-width: 320px) {
        order: 1;
    }
}

.headline > h5 {
    @media (min-width: 320px) {
        order: 2;
    }
}

.headline > h6 {
    @media (min-width: 320px) {
        order: 3;
    }
    @media (min-width: 1024px) {
        margin-top: -100px;
        grid-column: 2;
    }
}
.formulario {
    display: flex;
    justify-content: center;
    align-items: center;
}
.texto > p {
    color: #5f6368;
    font-family: 'Nunito' sans-serif;
    font-weight: 500;
    font-size: 22px;
    margin: 10px;

    @media (min-width: 768px) {
        font-size: 28px;
        width: 90%;
        margin: auto;
    }
}

.destaque {
    color: #56bb8f;
    font-family: 'Nunito' sans-serif;
    font-weight: 700;
}

.headline > .texto {
    @media (min-width: 320px) {
        order: 4;
    }
    @media (min-width: 1024px) {
        margin-top: 25%;
        text-align: center;
        order: 2;
    }
}
