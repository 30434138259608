@import '../../variables.scss';
@import '../../mixins.scss';

.profile {
    display: grid;
    grid-template-columns: 1fr 350px;
    gap: 30px;

    h2 {
        @include font-size(28);
        font-weight: 500;
        color: $text-dark;
    }

    &__left {
        display: flex;
        flex-direction: column;
        gap: 25px;
    }

    &__destaque {
        text-align: center;
        background-color: $light-color;
        padding: 20px 25px;
        height: fit-content;
        display: flex;
        flex-direction: column;
        gap: 20px;

        img {
           
            height: 150px;
            border-radius: 100px;
            align-self: center;
        }

        button {
            background-color: $info-color;
            color: $light-color;
            border: none;
            border-radius: 4px;
            padding: 0 20px;
            @include font-size(18);
            height: 40px;
            line-height: 40px;
            width: 100%;

            &:hover {
                background-color: darken($color: $info-color, $amount: 20);
            }
        }
    }

    &__form {
        display: grid;
        gap: 30px;
        background-color: $light-color;
        padding: 20px 25px;

        button {
            background-color: $info-color;
            color: $light-color;
            border: none;
            border-radius: 4px;
            padding: 0 20px;
            @include font-size(18);
            height: 40px;
            line-height: 40px;
           
            align-self: self-end;

            &:hover {
                background-color: darken($color: $info-color, $amount: 20);
            }
        }
    }

    &__two_columns {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 5px 30px;
    }

    &__card {
        display: grid;
        gap: 10px;

        label {
            @include font-size(18);
            font-family: 'Courier New', Courier, monospace;
        }

        input {
            border: none;
            height: 40px;
            line-height: 40px;
            border: none;
            border-radius: 4px;
            box-shadow: 0 0 4px #cfcfcf;
            padding: 0 15px;
            @include font-size(18);

            &:focus {
                outline: none;
            }
        }

        textarea {
            resize: none;
            border: none;
            height: 120px;
            border: none;
            border-radius: 4px;
            box-shadow: 0 0 4px #cfcfcf;
            padding: 10px 15px;
            @include font-size(18);

            &:focus {
                outline: none;
            }
        }

        &_invalid {
            border: 1px solid red !important;
        }
    }

    &__radio {
        label {
            @include font-size(18);
            font-family: 'Courier New', Courier, monospace;
            display: block;
        }

        &_grid {
            display: flex;
            gap: 15px;
            margin-top: 10px;
        }

        &_input {
            display: flex;
            place-items: center;

            label {
                padding-left: 10px;
            }
        }
    }

    small {
        @include font-size(14);
        color: $danger-color;
        font-weight: bold;
    }

    &__imagem {
        display: grid;
        gap: 10px;

        label {
            @include font-size(18);
            font-family: 'Courier New', Courier, monospace;
        }

        input {
            height: 40px;
            line-height: 40px;
            border: none;
            border-radius: 4px;
            @include font-size(18);
        }
    }

    small {
        @include font-size(12);
        color: $danger-color;
    }
}

.mensagem {
    text-align: center;

    font-family: 'Courier New', Courier, monospace;

    h2 {
        @include font-size(32);
        letter-spacing: 0.7px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    img {
       
    }

    p {
        @include font-size(18);
        margin-bottom: 10px;
    }
}
