@import '../variables.scss';
@import '../mixins.scss';

#ancora_privacidade {
    text-decoration: none;
    color: #000 !important;
    border-bottom: 1px solid #000;
    cursor: pointer;
}

.left_space {
    margin-left: 40px;
}

.ponteiro {
    position: relative;
}
.ponteiro::before {
    content: '';
    width: 9px;
    height: 9px;
    background-color: black;
    position: absolute;
    border-radius: 100px;
    left: -30px;
    bottom: 50%;
    transform: translateY(50%);
}
@media (max-width: 500px) {
    .ponteiro::before {
        left: -30px;
    }
    .left_space {
        margin-left: 25px;
    }
}

.textos {
    padding: 0 $page-gap-mobile;
    display: grid;
    gap: 25px;
    margin-bottom: 50px;

    @media (min-width: 1900px) {
        padding: 0 $page-gap;
    }

    h3 {
        margin: 20px 0;
        @include font-size(18);
        font-weight: 500;
    }

    p {
        margin: 10px 0;
    }

    ul {
        margin-left: 35px;
        list-style-type: lower-alpha;

        li {
            margin: 10px 0;
        }
    }

    &__lista_romana {
        ul {
            list-style-type: upper-roman;
        }
    }
}
