@import '../../variables.scss';
@import '../../mixins.scss';

.post {
    color: $text-dark;
    background-color: $light-color;
    border-radius: 4px;

    img {
        height: 150px;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
    }

    &__content {
        padding: 10px 20px;

        h3 {
            @include font-size(18);
            font-weight: 500;
            margin-bottom: 10px;
            letter-spacing: 0.5px;

            @media (min-width:1440px) {
                @include font-size(22);
            }
        }
    }
}