@import '../../../variables.scss';
@import '../../../mixins.scss';

.postagens {
    display: grid;
    gap: 30px;

    &__header {
        display: flex;
        justify-content: space-between;

        button {
            background-color: $info-color;
            color: $light-color;
            border: none;
            border-radius: 4px;
            padding: 0 20px;
            @include font-size(18);
            display: flex;
            place-items: center;
            gap: 5px;

            &:hover {
                background-color: darken($color: $info-color, $amount: 20);
            }
        }
    }

    &__itens {
        display: grid;
        gap: 20px;
    }

    &__topo {
        display: flex;
        justify-content: space-between;
    }

    &__arquivados {
        color: $light-color;
        border: none;
        border-radius: 4px;
        padding: 0 20px;
        @include font-size(18);
        background-color: $disabled-color;

        &:hover {
            background-color: darken($color: $disabled-color, $amount: 20);
        }
    }

    &__nao_arquivados {
        color: $light-color;
        border: none;
        border-radius: 4px;
        padding: 0 20px;
        @include font-size(18);
        background-color: $success-color;

        &:hover {
            background-color: darken($color: $success-color, $amount: 20);
        }
    }
}

.buscador {
    display: flex;
    gap: 20px;

    input {
        height: 40px;
        line-height: 40px;
        border: none;
        border-radius: 4px;
        box-shadow: 0 0 4px #cfcfcf;
        padding: 0 15px;
       
        @include font-size(18);

        &:focus {
            outline: none;
        }

        @media (min-width:1440px) {
           
        }
    }

    &__filtro {
        background-color: $info-color;
        color: $light-color;
        border: none;
        border-radius: 4px;
        padding: 0 20px;
        @include font-size(18);

        &:hover {
            background-color: darken($color: $info-color, $amount: 20);
        }
    }
}
