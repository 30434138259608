.container {
   display: grid;
   grid-template-columns: 1fr;
   gap: 20px;
   background-color: #0F1628;
   padding: 40px;

    @media (min-width:768px){
        grid-template-columns: 1fr 1fr;
    }

   @media(min-width:1024px){
    gap:20px;
    grid-template-columns: 1fr 1fr 1fr 1fr ;
    padding: 20px;
   }
}

.titulo {
    background-color: #0F1628;
    font-size: 30px;
    margin-top: 30px;
    padding-top: 20px;
    text-align: center;
    color:#e7edeb;
    font-weight: bolder;
    font-family: 'Nunito',sans-serif;
    @media (min-width:768px){
        font-size: 40px;
    }
}