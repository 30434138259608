.card {
    background-color: #f5f5f5;
    border-radius: 29px;
    display: flex;
    flex-direction: column;
    min-height: 550px;
    width: 28%;
}

.ctn_image {
    display: flex;
    margin: 0 auto;
    margin-top: -60px;
    justify-content: center;
    background-color: #f5f5f5;
    border-radius: 200px;
    padding-bottom: 50px;
    padding: 20px 0px 50px 0px;
    width: 35%;
    > img {
        max-width: 75px;
    }
}

.ctn_texto {
    text-align: center;
    padding: 0px 5% 0px 5%;
    font-family: 'Nunito', sans-serif;
    display: flex;
    flex-direction: column;
    gap: 5px;
    > h2 {
        font-size: 30px;
    }
    > p {
        font-size: 28px;
    }
    @media (max-width: 1500px) {
        > h2 {
            font-size: 28px;
        }
        > p {
            font-size: 22px;
        }
    }
}
