@import '../../mixins.scss';

.content {
    margin: 30px 50px 30px 300px;
    position: relative;

    @media (min-width:1440px) {
        margin: 35px 80px 30px 320px;
    }

    @media (min-width:1900px) {
        margin: 40px 150px 30px 400px;
    }
}
