.btn_main {
    text-decoration: none;
    color: white;
    min-width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 230px;
    border-radius: 64px;
    height: 55px;
    background-color: #48b485;
    border: none;
    font-family: 'Nunito', sans-serif;
    font-size: 25px;
    font-weight: 700;
    transition: all 300ms ease;
}
.btn_main:hover {
    background-color: white;
    border: 1px solid gray;
    color: #111;
    cursor: pointer;
    transform: scale(1.035);
}

.ctn_btn {
    display: flex;
    align-items: center;
    justify-content: end;
    max-width: 425px;
}
.btn_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000000;
    border-radius: 64px;
    padding: 5px;
    height: 56px;
    width: 60px;
    transform: translateX(-75%);
    > img {
        width: 90%;
    }
}
