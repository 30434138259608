.card {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    align-items:center;
    border-radius: 50px;
    box-shadow: -1px -1px 2px 1px  #28140f;
    @media (min-width:1024px){
        grid-template-columns: 1fr 2fr;
        gap: 10px;
        padding: 15px;
    }
}

.media > img {
    height: 115px;

    @media (min-width:768px){
        height: 140px;   
    }
}

.media {
    display: grid;
    justify-content: center;
}

.text > h2 {
    font-family: 'Nunito serif', sans-serif;
    font-size: 18px;
    color:#56BB8F;
    text-align: center;

    @media (min-width:1024px){
        font-size: 22px;
    }
}

.text > p {
    display: grid;
    text-align: justify;
    color: #5F6368;
    font-size: 16px;
    padding: 25px;

    @media (min-width:768px){
        font-size: 18px;
    }
    
    @media (min-width:1024px){
        font-size: 22px;
    }

}