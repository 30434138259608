@import url('https://fonts.googleapis.com/css2?family=Borel&family=Nunito+Sans:opsz@6..12&family=Nunito:wght@700&family=REM:ital,wght@1,300&family=Roboto:wght@400;500;700&display=swap');

.sobrevalid {
    font-family: 'Nunito', sans-serif;
    font-size: 30px;
    text-align: center;
}

.titulo {
    color:#56BB8F;
    font-family: 'Nunito', sans-serif;
    font-weight:bold;
}

.sobrevalid > h1 {
    margin:15px;
    color:#5F6368;
    font-size: 20px;
    margin-bottom: -20px;
    @media (min-width:768px){
        font-size: 36px; 
    }
    @media (min-width:1900px){
        font-size: 44px;
    }
}

.cards {
    display:grid;
    grid-template-columns: 2fr;
    gap: 20px;
    margin: auto;
    padding: 30px;

    @media (min-width:1024px){
        width: 65%;
        gap:30px;
    }
    @media (min-width:768px){}
}

