@import '../../variables.scss';
@import '../../mixins.scss';

.main {
    display: grid;
    gap: 50px;
    margin: 50px 0;

    h1,
    h2 {
        margin-bottom: 15px;
        @include font-size(18);

        @media (min-width:1024px) {
            @include font-size(24);
        }
    }

    p {
        margin-bottom: 5px;
    }

    img {
        width: 100%;
    }

    small {
        display: block;
        @include font-size(14);
        text-align: right;
    }
}

.destaque {
    display: grid;
    background-color: $light-color;
    border-radius: 4px;

    @media (min-width:1024px) {
        grid-template-columns: 1.2fr 1fr;
    }

    &__content {
        padding: 20px 30px;
        order: 2;

        @media (min-width:1024px) {
            order: 1;
            padding: 20px 50px;
        }

        h1 {
            @include font-size(24);

            @media (min-width:1024px) {
                @include font-size(32);
            }
        }

        a {
            display: inline-block;
            color: $black-color;
            padding: 7px;
           
            text-align: center;
            border: 1px solid $black-color;
            border-radius: 4px;
            margin-top: 15px;
            font-weight: bold;
            letter-spacing: 0.7px;
            transition: 0.1s ease-in-out;

            &:hover {
                border-color: $primary-color;
                background-color: $primary-color;
                color: $white-color;
            }
        }
    }

    img {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        height: 100%;
        order: 1;

        @media (min-width:1024px) {
            order: 2;
        }
    }
}

.recentes {
    display: grid;
    gap: 30px 50px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    @media (min-width:1024px) {
        grid-template-columns: repeat(3, 1fr);
    }

    img {
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
    }

    &__card {
        background-color: $light-color;
        padding-bottom: 20px;
        color: $text-dark;
    }

    &__content {
        padding: 10px 20px;
    }
}

.videos {
    background-color: $light-color;
    padding: 20px 25px 40px 25px;
    border-radius: 4px;

    &__posts {
        display: grid;
        gap: 30px;
        
        @media (min-width:1024px) {
            grid-template-columns: repeat(4, 1fr);
        }

        a {
            border-radius: 4px;
            background-color: $white-color;
            text-align: center;
            color: $text-dark;            
        }

        img {
            height: 250px;
            border-top-right-radius: 4px;
            border-top-left-radius: 4px;
        }

        h4 {
            padding: 15px 10px;
            font-weight: 500;
            @include font-size(18);
        }
    }

    &__titulo {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        a {
            color: $black-color;
            height: fit-content;
            align-self: end;
            transition: .15s ease-in-out;
            @include font-size(14);

            @media (min-width:1024px) {
                @include font-size(16);
            }

            &:hover {
                color: $info-color;
            }
        }
    }

    h2 {
        @include font-size(24);
        letter-spacing: 0.8px;
        font-weight: normal;
        color: $text-dark;

        @media (min-width:1024px) {
            @include font-size(28);
        }
    }
}

.noticias {
    display: grid;
    gap: 50px;

    @media (min-width:1024px) {
        grid-template-columns: 1fr 250px;
    }

    @media (min-width:1440px) {
        grid-template-columns: 1fr 300px;
    }

    aside {
        order: 1;

        @media (min-width:1024px) {
            order: 2;
        }
    }

    &__order {
        order: 2;

        @media (min-width:1024px) {
            order: 1;
        }
    }

    h2 {
        @include font-size(28);
        font-weight: normal;
        letter-spacing: 0.5px;
        margin-bottom: 25px;
    }

    &__recentes {
        display: grid;
        gap: 30px;
        height: fit-content;

        &_card {
            background-color: $light-color;
            border-radius: 4px;
            color: $text-dark;

            @media (min-width:1024px) {
                display: flex;
            }

            img {
                width: 100%;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;

                @media (min-width:1024px) {
                   
                }

                @media (min-width:1440px) {
                   
                }
            }

            div {
                padding: 20px;
            }

            h3 {
                @include font-size(18);
                font-weight: 500;
                margin-bottom: 10px;
                letter-spacing: 0.5px;

                @media (min-width:1440px) {
                    @include font-size(24);
                }
            }

            small {
                @include font-size(14);
            }
        }
    }

    &__titulo {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        a {
            color: $black-color;
            height: fit-content;
            align-self: end;
            transition: .15s ease-in-out;
            @include font-size(14);

            @media (min-width:1024px) {
                @include font-size(16);
            }

            &:hover {
                color: $info-color;
            }
        }
    }
}

.melhores {
    display: grid;
    gap: 30px;
}

.wrapper {
    margin: 0 30px;
    
    @media (min-width:1024px) {
        margin: 0 $page-gap-mobile;
    }

    @media (min-width:1900px) {
        margin: 0 $page-gap;
    }
}
