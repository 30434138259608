@import url('https://fonts.googleapis.com/css2?family=Borel&family=Nunito:wght@600&family=REM:ital,wght@1,300&display=swap');
@import '../../../variables.scss';
@import '../../../mixins.scss';

#estatistica {
    display: grid;
    padding: 10px $page-gap-mobile 80px $page-gap-mobile;
    @media (min-width: 768px) {
    }

    @media (min-width: 1024px) {
        width: 100%;
    }

    @media (min-width: 1900px) {
        padding: 10px $page-gap 40px $page-gap;
    }

    @media (min-width: 1024px) {
        padding: 0;
    }
    & img {
        width: 100%;
        max-width: 250px;
        padding: 10px;
    }
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
}

button > button {
    background-color: $primary-color;
    height: 50px;
    border-radius: 40px;
    color: white;
    font-size: 18px;
    text-align: center;
    margin-top: 60px;
    margin-bottom: -20px;
}

#estatistica {
    background-color: #0f1628;
    padding: 10px $page-gap-mobile 80px $page-gap-mobile;

    @media (min-width: 1900px) {
        padding: 10px $page-gap 40px $page-gap;
    }
}
#estatistica__desktop {
    background-color: #031028;
    padding: 10px $page-gap-mobile 80px $page-gap-mobile;

    @media (min-width: 1900px) {
        padding: 10px $page-gap 40px $page-gap;
    }
}

#estatistica > h2 {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 20px;
    font-family: 'Nunito', sans-serif;
    @media (min-width: 768px) {
        font-size: 26px;
    }

    @media (min-width: 1024px) {
        font-size: 32px;
    }
}

#elementos {
    font-size: 16px;
    display: grid;
    grid-template-columns: 1fr;
    background-color: #fff;
    border-radius: 50px;
    padding: 20px;

    @media (min-width: 768px) {
        margin: auto;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    & p {
        font-weight: bold;
        text-align: center;
        font-size: 16px;
        color: white;
        font-family: 'Nunito', sans-serif;

        @media (min-width: 1024px) {
            font-size: 40px;
        }
    }
}

#elementos__desktop {
    font-size: 16px;
    display: grid;
    display: flex;
    grid-template-columns: 1fr;
    min-height: 300px;

    @media (min-width: 768px) {
        margin: auto;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    & p {
        font-weight: bold;
        text-align: center;
        font-size: 16px;
        color: white;
        font-family: 'Nunito', sans-serif;

        @media (min-width: 1024px) {
            font-size: 40px;
        }
    }
}
#titulo_desktop {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    font-size: 35px;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    color: #fff;
}
#elementos div {
    @media (min-width: 768px) {
        display: grid;
        align-content: space-between;
        border-right: 1px solid #ccc;
        padding: 0 10px;
    }
}

#elementos div:last-child {
    border-right: none;
}

h3 {
    color: #56bb8f;
    font-size: 16px;

    @media (min-width: 1024px) {
        font-size: 20px;
    }
    @media (min-width: 1900px) {
        font-size: 36px;
    }
}

#grafico {
    text-align: center;
}

#grafico > h3 {
    font-weight: 500;
}

#grafico > p {
    font-family: 'Nunito sans', sans-serif;
    font-size: 18px;
    font-weight: 600;

    @media (min-width: 1024px) {
        font-size: 40px;
    }
}

#lojas {
    text-align: center;
}

#lojas > h3 {
    font-weight: 500;
}

#lojas > p {
    font-family: 'Nunito sans', sans-serif;
    font-weight: 600;

    @media (min-width: 1024px) {
        font-size: 40px;
    }
}

#pessoas > h3 {
    font-weight: 500;
}

#pessoas {
    text-align: center;
}

#pessoas > p {
    font-family: 'Nunito sans', sans-serif;
    font-size: 18px;
    font-weight: 600;

    @media (min-width: 1024px) {
        font-size: 40px;
    }
}

#graficos > h3 {
    font-weight: 500;
}

#graficos > p {
    font-family: 'Nunito sans', sans-serif;
    font-size: 18px;
    font-weight: 600;

    @media (min-width: 1024px) {
        font-size: 40px;
    }
}

#like {
    text-align: center;
    @media (min-width: 768px) {
    }

    @media (min-width: 1024px) {
    }
}

#like > h3 {
    font-weight: 500;
    font-size: 20px;
    @media (min-width: 1900px) {
        font-size: 30px;
    }
}

#like > p {
    font-family: 'Nunito sans', sans-serif;
    font-size: 18px;
    font-weight: 600;

    @media (min-width: 1024px) {
        font-size: 40px;
    }
}
