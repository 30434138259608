@import '../variables.scss';
@import '../mixins.scss';

.bg_image {
    background-image: url('../assets/images/notfound.jpg');
    width: 100%;
    height: 100vh;
    filter: blur(5px);
}

.error {
    @include center(both, absolute);
    text-align: center;
    z-index: 2;

    h1 {
        @include font-size(180);
        font-weight: 700;
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        background-image: url('../assets/images/text_bg.jpg');
        background-position: right;

        @media (min-width:1024px) {
            @include font-size(250);
        }
    }

    h3,
    p {
        font-family: 'Courier New', Courier, monospace;
    }

    h3 {
        @include font-size(30);
        font-weight: 700;
        margin-bottom: 10px;
    }

    p {
        @include font-size(24);
        font-weight: 500;
        margin-bottom: 30px;
    }

    a {
        background-color: $success-color;
        color: #fff;
        padding: 10px 20px;
        border-radius: 10px;
        text-transform: uppercase;
        transition: 0.15s ease-in-out;

        &:hover {
            background-color: darken($color: $success-color, $amount: 15);
        }
    }
}