$primary-color: #48b485;
$secundary-color: #145a3c;
$dark-color: #0F1628;
$light-color: #f1f1f1;
$success-color: #0e9d0e;
$danger-color: #e02e22;
$info-color: #2563eb;
$black-color: #000;
$white-color: #fff;
$disabled-color: #a1a1a1;
$gray-header-color:#998D80;
$text-dark: #404040;
$white-icon-color:#FFFF;
$page-gap-mobile: 12%;
$page-gap: 20%;
