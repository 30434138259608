@import '../../variables.scss';
@import '../../mixins.scss';

.menu {
    @include position(fixed, top 0 bottom 0 left 0);
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 1)), url('../../assets/images/side_menu.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    box-shadow: 5px 0 10px #b1b1b1;
    color: $white-color;
    padding: 20px 0;
}

.perfil {
    text-align: center;

    &__img {
        height: 80px;
        border-radius: 50%;
        margin-top: 20px;
        margin-bottom: 5px;
    }

    &__funcao {
        font-weight: normal;
        letter-spacing: 0.5px;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.35);
        margin: 0 15px;
    }
}

.dropdown {
    position: relative;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.35);
    margin: 0 15px;

    &__btn {
        background-color: transparent;
        border: none;
        display: flex;
        place-items: center;
        width: 100%;
        justify-content: center;
    }

    &__menu {
        list-style-type: none;
        margin: 0 10px;
        border: 1px solid grey;
        @include position(absolute, left 0 right 0);
        background-color: $text-dark;

        & > li {
            background-color: transparent;
            transition: 0.1s ease-in-out;

            &:hover {
                background-color: rgba(255, 255, 255, 0.15);
            }

            & > button {
                width: 100%;
                height: 100%;
                text-align: center;
                background: none;
                color: inherit;
                border: none;
                padding: 8px;
                margin: 0;
                cursor: pointer;
            }
        }
    }
}

.navbar {
    display: grid;
    margin: 20px 15px;
    gap: 8px;

    &__link {
        padding: 15px;
        color: rgba(255, 255, 255, 0.75);
        letter-spacing: 0.5px;
        transition: 0.1s ease-in-out;
        display: flex;
        place-items: center;
        gap: 10px;

        &:hover {
            border-radius: 4px;
            background-color: $secundary-color;
        }
    }

    &__active {
        border-radius: 4px;
        font-weight: bold;
        background-color: $secundary-color;
    }
}
