@import '../../variables.scss';

.formulario__desktop {
    margin-top: 30px;
    padding: 25px;
    display: flex;
    row-gap: 1px;
    flex-direction: column;
    justify-content: start;
    width: 240px;
    height: 600px;
    border-radius: 30px;
    > #titulo {
        color: white;
        text-align: center;
        font-family: 'Nunito', sans-serif;
        font-weight: 800;
        font-size: 20px;
    }
    > #select_item {
        display: flex;
        margin: 0 auto;
        height: 50px;
        border-radius: 10px;
        border: none;
        width: 360px;
        font-family: 'Nunito', sans-serif;
        font-size: 18px;
        &:focus {
            outline: none;
        }
    }

    @media (min-width: 769px) {
        margin: auto;
        padding: 25px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 430px;
        height: 700px;
        background-color: #48b485;
        border-radius: 30px;
    }
    @media (min-width: 1024px) {
        width: 400px;
    }
    @media (min-width: 1440px) {
        width: 440px;
    }
}

.formulario__desktop > label {
    font-size: 16px;
    color: #fff;
    font-family: 'Nunito', sans-serif;
    font-weight: normal;
    font-size: 20px;
    @media (min-width: 1440px) {
        margin-left: 20px;
    }
}

.formulario__desktop > input {
    font-family: 'Poppins', sans-serif !important;
    font-size: 20px;
    border: none;
    padding-left: 4px;
    color: #0f1628;
    background-color: white;
    width: 180px;
    border-radius: 10px;

    &:focus {
        outline: none;
    }

    @media (min-width: 769px) {
        font-size: 20px;
        padding-left: 4px;
        color: #0f1628;
        background-color: white;
        width: 360px;
        height: 50px;
        border-radius: 10px;
        font-family: 'Nunito', sans-serif;
    }
    @media (min-width: 1024px) {
        width: 330px;
    }
    @media (min-width: 1440px) {
        margin: 0 auto;
        width: 360px;
    }
}

.formulario__desktop > div > .button {
    border-radius: 50px;
    height: 70px;
    width: 70%;
    background-color: #031028;
    color: #fff;
    font-size: 18px;
    font-family: 'Nunito', sans-serif;
    font-weight: 800;
    &:hover {
        transform: scale(1.025);
        cursor: pointer;
    }
}

.ctn_button {
    display: flex;
    justify-content: center;
    margin: 30px 0px 10px 0px;
}

.formulario__desktop > .button {
    margin: 10px auto;
}
.formulario__desktop > #legenda {
    display: flex;
    width: 75%;
    text-align: center;
    margin: 0 auto;
    color: white;
    font-family: 'Nunito', sans-serif;
    font-size: 13px;
    &:hover {
        color: #eee;
    }
}

.formulario {
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 220px;
    height: 450px;
    background-color: $dark-color;
    border-radius: 30px;

    @media (min-width: 768px) {
        width: 400px;
        height: 470px;
        margin: auto;
    }
}

.formulario > label {
    font-size: 14px;
    margin-top: 20px;
    color: #fff;
}

.formulario > input {
    font-size: 20px;
    padding-left: 4px;
    color: #0f1628;
    background-color: white;
    width: 170px;
    height: 32px;
    border-radius: 10px;
    @media (min-width: 768px) {
        font-size: 20px;
        width: 350px;
    }
}

.formulario > button {
    opacity: 0.9;
    margin-top: 20px;
    height: 45px;
    border-radius: 50px;
    background-color: $primary-color;
    color: #fff;
    font-weight: 600;
}

.formulario > button > p {
    font-size: 14px;

    @media (min-width: 768px) {
        font-size: 18px;
    }
}

.estilo {
    font-weight: 600;
    color: #0f1628;
}

.texto {
    opacity: 0.9;
    display: flex;
    text-align: center;
    margin-top: 10px;

    @media (min-width: 1024px) {
        margin-top: 20%;
    }
}

.texto:hover {
    opacity: 1;
}

.formulario > button:hover {
    opacity: 1;
}

.texto > p {
    color: #5f6368;
    font-family: 'Nunito' sans-serif;
    font-weight: 500;
    font-size: 22px;
    margin: 10px;

    @media (min-width: 768px) {
        font-size: 28px;
        width: 90%;
        margin: auto;
    }
}

.destaque {
    color: #56bb8f;
    font-family: 'Nunito' sans-serif;
    font-weight: 700;
}
