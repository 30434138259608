.container {
    display: flex;
    flex-direction: column;
    min-height: 450px;
    gap: 40px;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    max-width: 600px;
    border-radius: 15px;
    transition: all 200ms ease;

    > h2 {
        font-size: 28px;
        font-family: 'Poppins', sans-serif;
    }

    > div {
        background-color: #cffce9;
        padding: 30px 20px;
        > p {
            font-weight: 600;
            color: #111;
            text-align: center;
            font-size: 22px;
        }
    }
}

.img {
    max-width: 250px;
}
