@import '../../variables.scss';
@import '../../mixins.scss';

.main {
    display: grid;
    gap: 50px;
    margin: 50px 0;

    h1,
    h2 {
        margin-bottom: 15px;
        @include font-size(18);

        @media (min-width:1024px) {
            @include font-size(24);
        }
    }

    p {
        margin-bottom: 5px;
    }

    img {
        width: 100%;
    }

    small {
        display: block;
        @include font-size(14);
        text-align: right;
    }
}

.videos {
    background-color: $light-color;
    padding: 20px 25px 40px 25px;
    border-radius: 4px;

    &__posts {
        display: grid;
        gap: 30px;

        @media (min-width:768px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (min-width:1024px) {
            grid-template-columns: repeat(3, 1fr);
        }

        @media (min-width:1440px) {
            grid-template-columns: repeat(4, 1fr);
        }

        a {
            border-radius: 4px;
            background-color: $white-color;
            text-align: center;
            color: $text-dark;
        }

        img {
            height: 250px;
            border-top-right-radius: 4px;
            border-top-left-radius: 4px;
        }

        h4 {
            padding: 15px 10px;
            font-weight: 500;
            @include font-size(18);
        }
    }

    &__titulo {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        a {
            color: $black-color;
            height: fit-content;
            align-self: end;
            transition: 0.15s ease-in-out;
            @include font-size(14);

            @media (min-width:1024px) {
                @include font-size(16);
            }

            &:hover {
                color: $info-color;
            }
        }
    }

    h2 {
        @include font-size(24);
        letter-spacing: 0.8px;
        font-weight: normal;
        color: $text-dark;

        @media (min-width:1024px) {
            @include font-size(28);
        }
    }
}

.wrapper {
    margin: 0 30px;

    @media (min-width:1024px) {
        margin: 0 $page-gap-mobile;
    }

    @media (min-width:1900px) {
        margin: 0 $page-gap;
    }
}
