.ctn_imagem {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}
.imagem {
    width: 90px;
    height: 100px;
}

.data {
    color: #fff;
    font-family: 'Nunito', sans-serif;
    font-size: 35px;
}
.titulo {
    color: #fff;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    text-align: center;
}
