.containerLeft{
    @media (min-width:768px){
        height: 215px;
        }

    @media (min-width:1024px){
    height: 400px;
    margin-bottom: 30px;

    }
    @media (min-width:1440px){
        margin-bottom: -25px;
    }
    @media (min-width:1900px){
        margin-bottom: 55px;

    }
}

.containerRight{
    @media (min-width:768px){
        height: 280px;
        margin-bottom: -50px;
        }

    @media (min-width:1024px){
        margin-left: -120px;
        height: 370px;
        }

        @media (min-width:1440px){
            margin-bottom: -120px;
            height: 370px;
        }
        @media (min-width:1900px){
            height: 370px;
            }
    }