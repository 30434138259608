.container {
    background-color: #d9d9d9;
    padding: 4% 7% 5% 7%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 100px;
    > div > h2 {
        font-family: 'Nunito', sans-serif;
        color: #031028;
        font-weight: 800;
        font-size: 32px;
        padding-bottom: 20px;
    }
    > div > .texto {
        width: 70%;
        font-size: 28px;
        margin-bottom: 20px;
    }
}

.cards {
    display: flex;
    justify-content: space-around;
    width: 80%;
    margin: 0 auto;
}
.container > #texto {
    padding-left: 12%;
}

.btn_contato {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 600px;
    min-width: 250px;
    text-align: center;
    height: 80px;
    margin-bottom: 5px;
    background-color: #031028;
    font-family: 'Nunito', sans-serif;
    font-weight: 900;
    font-size: 20px;
    padding: 12px 24px;
    border: none;
    border-radius: 60px;
    transition: 200ms all ease;
    &:hover {
        transform: scale(1.025);
        background-color: white;
        border-bottom: 2px solid #111;
        color: #000;
    }
}
