* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: 'Segoe UI', 'Roboto', 'Oxygen', sans-serif;
}

html {
    scroll-behavior: smooth;
    scroll-padding-top: 60px;
}

a {
    text-decoration: none;
}

p {
    font-size: 14px;
}

button {
    color: #fff;
    cursor: pointer;
    letter-spacing: 0.5px;
}

ul {
    list-style-type: none;
}

input[type='radio'] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    appearance: none;
    /* For iOS < 15 to remove gradient background */
    background-color: #fff;
    /* Not removed via appearance */
    margin: 0;

    font: inherit;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid #5e5e5e;
    border-radius: 50%;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
}

input[type='radio']::before {
    content: '';
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #56bb8f;
    background-color: CanvasText;
}

input[type='radio']:checked::before {
    transform: scale(1);
}

.slick-slide img {
    display: inline;
}

.slick-list {
    margin: 0 -5px;
}
.slick-slide > div {
    padding: 1px 5px;
}

@media (min-width: 992px) {
    p {
        font-size: 16px;
    }
}
