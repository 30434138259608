@import url('https://fonts.googleapis.com/css2?family=Borel&family=Nunito:wght@600&family=REM:ital,wght@1,300&display=swap');
@import '../../variables.scss';
@import '../../mixins.scss';

.container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 5% 10% 5% 10%;
}
.titulo,
.conteudo {
    color: #262626;
    font-family: 'Nunito', sans-serif;
}
.titulo {
    font-weight: 700;
    font-size: 36px;
    width: 80%;
    letter-spacing: 1.3px;
}
.conteudo {
    font-weight: 400;
    padding: 15px 0px 45px 0px;
    font-size: 26px;
    width: 75%;
}

.headline {
    padding: 20px $page-gap-mobile;
    background-color: #ffff;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;

    @media (min-width: 768px) {
        padding: 40px $page-gap-mobile;
        grid-template-columns: 1fr;
        justify-content: space-between;
    }

    @media (min-width: 1024px) {
        grid-template-columns: minmax(500px, 1fr) minmax(300px, 625px);
        gap: 70px;
        padding: 45px;
    }

    @media (min-width: 1900px) {
        padding: 20px $page-gap;
    }

    // & h5 {
    //     border-radius: 5px;
    //     @media (min-width:320px){
    //         order: 2;
    //         display: flex;
    //     }
    //     }

    //     @media (min-width:768px) {
    //         margin-top: 30px;
    //         place-self: start;
    //     }
    //     @media (min-width:1024px){
    //         display: grid;
    //         grid-column: 1;;
    //     }
    // }

    &__text {
        display: flex;
        gap: 10px;
        flex-direction: column;
        color: $white-color;

        @media (min-width: 320px) {
            display: flex;
            order: 1;
        }

        @media (min-width: 768px) {
            grid-column: 1;
        }

        &__iframe {
            @media (min-width: 768px) {
                grid-column: 2;
            }

            iframe {
                width: 100%;
                height: 400px;
                border: none;
            }
        }

        & p {
            display: inline;
            font-size: 24px;
            color: $primary-color;
        }

        & h1 {
            @include font-size(36);
            font-family: 'Nunito', sans-serif;
            color: $primary-color;

            @media (min-width: 768px) {
                @include font-size(32);
            }

            @media (min-width: 1900px) {
                @include font-size(36);
            }
        }

        & h4 {
            text-align: left;
            font-family: 'Nunito', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            color: #5f6368;
            margin-bottom: 15px;
            margin-right: 5%;

            @media (min-width: 768px) {
                font-size: 28px;
                margin-right: none;
            }

            @media (min-width: 1900px) {
                font-size: 26px;
            }
        }

        & h3 > a {
            font-size: 28px;
            color: rgb(235, 225, 225);
            padding: 8px;
            margin: auto;
            padding-left: 35px;
            padding-right: 35px;

            @media (min-width: 768px) {
                @include font-size(22);
            }

            @media (min-width: 1900px) {
                @include font-size(30);
            }
        }

        & a {
            border: none;
            background-color: $dark-color;
            font-size: 18px;
            font-weight: bold;
            height: 40px;
            line-height: 40px;
            border-radius: 20px;
            margin-top: 30px;
            color: $white-color;
            text-align: center;
            opacity: 0.9;

            @media (min-width: 768px) {
                border: 1px solid $dark-color;
                transition: 0.2s ease-in-out;

                &:hover {
                    background-color: $dark-color;
                    opacity: 1;
                }
            }

            @media (min-width: 1024px) {
                height: 50px;
                line-height: 50px;
                border-radius: 25px;
            }
        }
    }
}

.headline__text > h1 {
    font-family: 'Nunito', sans-serif;
    text-align: center;
    font-size: 30px;
    @media (min-width: 1024px) {
        font-size: 50px;
    }
}

.destaque_texto {
    font-family: 'Nunito serif', sans-serif;
    color: $primary-color;
}

.headline > .headline__text {
    @media (min-width: 320px) {
        order: 1;
    }
}

.headline > h5 {
    @media (min-width: 320px) {
        order: 2;
    }

    @media (min-width: 1024px) {
        grid-column: 1;
        order: 2;
    }
}

.headline > h6 {
    @media (min-width: 320px) {
        margin-left: 15px;
        order: 3;
    }
    @media (min-width: 768px) {
        margin-left: 0px;
    }
    @media (min-width: 1024px) {
        margin-top: -410px;
        grid-column: 2;
    }
}
