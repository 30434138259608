@import '../../variables.scss';
@import '../../mixins.scss';

.action {
    display: grid;
    gap: 25px 50px;
    padding: 20px;
    background-color: $primary-color;
    color:white;

    @media (min-width:1024px) {
        padding: 20px $page-gap-mobile;
        grid-template-columns: 1fr 400px;
    }

    @media (min-width:1900px) {
        padding: 20px 20%;
    }

    &__sender {
        position: relative;
        height: fit-content;
        align-self: center;

        &_icon {
            @include position(absolute, left 2px top 8.5px);
        }
    }

    h3 {
        color:$dark-color;
        font-size: 20px;
        font-weight: normal;

        @media (min-width:1440px) {
            font-size: 40px;
        }

        @media (min-width:768px) {
            font-size: 25px;
        }

        @media (min-width:1024px){
            text-align: start;
            font-size: 26px;
        }
        @media (min-width:1900px){
            font-size: 36px;
        }

    }

    p {
        font-size: 12px;

        @media (min-width:768px) {
            font-size: 18px;
        }

        @media (min-width:1440px) {
            font-size: 22px;
        }
        @media (min-width:1900px){
            font-size: 30px;
        }
    }

    input {
        height: 35px;
        line-height: 35px;
        width: 70%;
        border-radius: 4px;
        border: none;
        padding-left: 30px;
        padding-right: 100px;
        @media (min-width:768px) {
            width: 77%;
        }
        @media (min-width:1024px){   
            width: 92%;
        }
        &:focus {
            outline: none;
        }
    }

    button {
        @include position(absolute, right 0 top 0 bottom 0);
        background-color: $dark-color;
        border: none;
        display: flex;
        place-items: center;
        gap: 8px;
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
        justify-content: center;
        margin-right: 30px;
        padding-left: 5px;
        @media (min-width:768px) {
            margin-right: 165px;
        }
        @media (min-width:1024px){
            margin-right: 30px;
        }
    }
}

.mensagem {
    text-align: center;

    h2 {
        @include font-size(32);
        letter-spacing: 0.7px;
        font-weight: 500;
        margin-bottom: 20px;
    }

    p {
        @include font-size(18);
        margin-bottom: 10px;
    }
}

.erro {
    h2,
    p {
        color: red;
    }
}

.sucesso {
    h2,
    p {
        color: green;
    }
}

.destaque {
    margin-left: 5px;
    margin-right: 5px;
    color:white;
    font-family: 'Nunito', sans-serif;


}

.action__sender_icon{
 margin-left: 3px;
}