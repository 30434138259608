@import '../../../variables.scss';
@import '../../../mixins.scss';

@import url('https://fonts.googleapis.com/css2?family=Borel&family=Nunito:wght@600&family=REM:ital,wght@1,300&display=swap');

.clientes {
    background-color: #0f1628;
    padding: 10px $page-gap-mobile 20px $page-gap-mobile;

    @media (min-width: 1900px) {
        padding: 10px $page-gap 40px $page-gap;
    }

    @media (min-width: 768px) {
        width: 100%;
    }

    @media (min-width: 1024px) {
        padding: px $page-gap 40px $page-gap;
    }

    & img {
        width: 80%;
        height: 100%;
        margin-left: 5%;

        @media (min-width: 1024px) {
            height: 150px;
        }
    }
}

.slick-prev,
.slick-next {
    background: #000;
    color: #fff;
    border: none;
    padding: 10px;
    cursor: pointer;
    @media (min-width: 769px) {
        .slick-prev,
        .slick-next {
            z-index: 1;
            width: 60px;
            height: 60px;
            display: none !important;

            color: black;
        }

        .slick-prev:before,
        .slick-next:before {
            font-size: 60px;
            color: black;
            background: none;
            @media (min-width: 769px) {
                display: none !important;
            }
        }
    }
}

.slick-prev:hover,
.slick-next:hover {
    background: #555;
}

.setas__desktop {
    background-color: black;
}
#prev_arrow {
    @media (min-width: 1000px) {
        position: absolute;
        left: -5% !important;
    }
    @media (min-width: 1740px) {
        position: absolute;
        left: 15% !important;
    }
}
#next_arrow {
    @media (min-width: 1000px) {
        position: absolute;
        right: -2% !important;
    }

    @media (min-width: 1740px) {
        position: absolute;
        right: 15% !important;
    }
}
.clientes__items__desktop {
    background-color: white;
    width: 85%;
    display: flex;
    margin: 0 auto;

    @media (min-width: 1900px) {
        padding: 10px $page-gap 40px $page-gap;
    }

    & img {
        background-color: transparent;
        display: flex;
        width: 80%;
        margin: 0 auto 0px 15px;
        height: 180px;
        object-fit: contain;
    }
}

.logo {
    &_cliente,
    &_panvel,
    &_condor {
        border-radius: 5px;
        box-shadow: 0 0 5px #a8a8a8;
    }

    &_cliente {
        background-color: #fff;
        height: 175px;
        @media (min-width: 769px) {
            border-radius: 300px;
            width: 180px !important;
            padding: 20px;
        }
    }
    &_bomdia {
        background-color: #fff;
        height: 175px;
        display: flex;
        align-items: center;
        justify-content: center;
        & a > img {
            height: 75%;
            width: 90%;
            margin-top: 20px;
        }
    }

    &_panvel {
        background-color: #143c8d;
    }

    &_condor {
        background-color: #2e65a5;

        &_condor {
            height: 40px;
        }
    }
}

.logo {
    &_cliente__desktop,
    &_panvel,
    &_condor {
        border-radius: 300px;
        box-shadow: 0 0 5px #eee;
        background-color: #fff;
    }

    &_panvel {
        background-color: #143c8d;
    }
}

.button {
    opacity: 0.9;
    border: none;
    background-color: #56bb8f;
    border-radius: 10px;
    color: white;
    font-size: 20px;
    font-family: 'Nunito serif', sans-serif;
    font-weight: bold;
    padding: 8px;
    display: flex;
    margin: 0 auto;
    margin-top: 20px;

    @media (min-width: 769px) {
        display: grid;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 0px;
        padding: 14px;
    }
    @media (min-width: 1024px) {
        margin: auto;
        margin-top: 20px;
    }
}
.button__desktop {
    border: none;
    background-color: #56bb8f;
    border-radius: 100px;
    color: white;
    font-size: 20px;
    font-family: 'Nunito serif', sans-serif;
    font-weight: bold;
    padding: 20px 44px;
    margin-top: 20px;
    display: flex;
    margin: 40px auto 100px auto;
    transition: 200ms ease-in-out;
}
.button__desktop:hover {
    transform: scale(1.045);
}

.button:hover {
    opacity: 1;
}

.titulo_thispage {
    display: grid;
    color: #ffffff;
    margin: 10px;
}
.titulo_thispage__desktop {
    display: grid;
    color: #000000;
    margin: 10px;
}

.titulo_thispage > p {
    margin: auto;
    text-align: center;
    font-size: 28px;
    font-family: 'Nunito serif', sans-serif;
}

.titulo_thispage__desktop > p {
    margin: 60px auto 40px auto;
    text-align: center;
    font-size: 38px;
    font-family: 'Nunito serif', sans-serif;
}

.destacado {
    color: #56bb8f;
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
}
