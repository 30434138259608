@import '../../mixins.scss';

.backdrop {
    @include position(fixed, left 0 top 0);
    width: 100%;
    height: 100vh;
    z-index: 200;
    background-color: rgba(0, 0, 0, 0.75);
}

.modal {
    @include center(vertical, fixed);
    left: 5%;
    width: fit-content;
    background-color: white;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    z-index: 300;
    animation: slide-down 300ms ease-out forwards;

    @media (min-width:768px) {
        left: calc(50% - 18rem);
        padding: 20px 30px;
    }
}

@keyframes slide-down {
    from {
        opacity: 0;
        transform: translateY(-70%);
    }
    to {
        opacity: 1;
        transform: translateY(-50%);
    }
}
