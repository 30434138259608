.container {
    background-color: #031028;
    background-image: url('../../../assets/images/desktop/homeDesktop\ 1.png');
    background-size: contain;
    background-position: center right;
    background-repeat: no-repeat;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 30px;
    @media (min-width: 1800px) {
        padding-bottom: 150px;
    }
}

.titulo,
.texto {
    color: #fff;
    font-family: 'Nunito', sans-serif;
}
.titulo {
    font-weight: 700;
    font-size: 30px;
    width: 50%;
}

.texto {
    font-weight: 400;
    font-size: 24px;
    width: 50%;
}

.btn_contato {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #48b485;
    padding: 12px 16px;
    border: none;
    width: 50%;
    height: 65px;
    color: white;
    min-width: 150px;
    border-radius: 64px;
    cursor: pointer;
    transition: 300ms ease;
    font-family: 'Nunito', sans-serif;
    font-weight: 900;
    font-size: 20px;
    &:hover {
        transform: scale(1.025);
    }
    @media (max-width: 1750px) {
        font-size: 16px;
    }
}

.ctn_btn {
    width: 50%;
}

.container_textos {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding-top: 100px;
    gap: 40px;
}
