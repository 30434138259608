.card {
    display: flex;
    justify-content: center;
    @media (min-width:768px){
    margin-top: 110px;
    }
}



.media > img {
    display: none;
    @media(min-width:768px){
        margin-left: -60px;
        height: 190px;
        margin-top: 90px;
    }
    
   @media (min-width:1024px){
    display: grid;
    height: 240px;
    margin-top: 155px;
    }

   @media (min-width:1900px){
        height: 320px;
    }
    @media (min-width:1440px){
        margin-top: 100px;
        }
}

.icon {
    width: 26px;
    height: 26px;
    stroke: #56bb8f;
    margin-right: 15px;
}


@media (min-width:320px){
    .icon {
        margin-left: 0px;
    }
}

@media (min-width:1024px){
    .icon {
        margin-left: -40px;
    }
}


.media {
    display: grid;
    margin: 15px;
    justify-content: center;
    @media(min-width: 1024px){
        
    }
}

.text {
    font-family:'Nunito',sans-serif;
    font-weight: bold;
    margin-top: 20px;
    color:#0F1628;
    @media (min-width:320px){
        margin-top:80px;
        font-size: 18px;
    }

    @media (min-width:768px){
        font-size: 22px;
    }

    @media(min-width: 1024px){
        font-size:28px;
        margin-top: 20px;

    }

    @media (min-width:1900px){
        font-size: 40px;
    }
}

.text > p {
    text-align: justify;
    color: #5F6368;
    font-size:20px;
}

.elementos {
    position: relative;
    display: grid;
    text-align: center;
    gap: 10px;
    box-shadow: -1px -1px 2px 1px  #0F1628;
    width: 1250px;
    padding: 15px;

    @media (min-width:320px){
        width:300px;
        margin: 20px; 
        
    }

    @media (min-width:768px){
        width: 600px;
        margin-top: -30px;
    }


     @media (min-width:1024px){
         width: 1250px;
         padding: 55px;
         margin-top: 30px;
         }

    @media (min-width:1900px){
        width: 1550px;
        margin-top: 100px;
        margin-right:150px;
     
        }
}

.lista {
    list-Style:none;
}

.lista > p {
    display: flex;
    justify-content: left;
    align-items: center;
    text-align: start;
    color:#5F6368;
    font-size: 14px;
    
    @media(min-width:1024px){
        font-size: 20px;
    }
    @media (min-width:1900px){
        font-size: 26px;
    }
}

.media {
    position: absolute;
    top: 0;
    right: 0;
    height: 210px;
    width: 230px;

    @media (min-width:320px){
        display: inline;
        top: -10px;
        right: 75px;
        width: 100px;
        height: 90px;
    
    }

    @media (min-width:768px){
        display:inline-block;
        width: 75px;
        height: 55px;
        }

    @media (min-width:1024px){
        margin-top:20px;
        right: -30px;
        display: inline-block;
        width: 200px;
        height: 180px;
    }
}