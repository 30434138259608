@import '../../variables.scss';
@import '../../mixins.scss';

.card {
    padding: 10px 15px;
    border-radius: 10px;
    box-shadow: 0px 20px 30px 10px #56bb8f77;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;

    @media (min-width: 768px) {
        gap: 10px;
        padding: 10px 0px;
        min-height: 290px;
    }
}
