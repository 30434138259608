@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
@import './variables.scss';
@import './mixins.scss';

* {
    margin: auto;
    padding: 0;
    box-sizing: border-box;
}

.action {
    display: grid;
    grid-template-columns: 1fr;
    background-color: #0f1628;
    padding: 30px $page-gap-mobile;
    gap: 20px;
    color: #fff;
    text-align: center;
    font-size: 18px;

    @media (min-width: 768px) {
        grid-template-columns: 1fr 4fr 2fr;
    }

    @media (min-width: 1024px) {
        grid-template-columns: 1fr 2fr 1fr;
    }

    & h2 {
        font-family: 'Nunito', sans-serif;
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 20px;
        text-align: center;

        @media (min-width: 768px) {
            font-size: 22px;
        }

        @media (min-width: 1024px) {
            font-size: 24px;
        }
    }

    @media (min-width: 1900px) {
        padding: 30px 20%;
    }
    @media (min-width: 768px) {
        font-size: 25px;
    }

    p {
        color: #56bb8f;
    }

    & button {
        opacity: 0.9;
        background-color: $primary-color;
        border-radius: 10px;
        border: 2px solid $primary-color;
        color: $white-color;
        text-align: center;
        font-size: 20px;

        &:hover {
            opacity: 1;
        }
        @media (min-width: 768px) {
            font-size: 20px;
        }
    }
}

.content {
    gap: 15px;
    & h6,
    p {
        display: inline;
        font-size: 14px;

        text-align: justify;
        @media (min-width: 1024px) {
            font-size: 22px;
        }
    }
}

.aside {
    display: grid;
    grid-template-columns: 2fr;
    justify-content: center;
    align-items: center;
}

.aside > a > button {
    font-size: 18px;
    font-weight: bold;
    padding: 10px;

    @media (min-width: 1024px) {
        display: grid;
        grid-template-columns: 1fr;
        margin: auto;
    }
}

.header {
    text-align: center;
    @media (min-width: 1024px) {
        margin-bottom: 20px;
    }
}

.middle {
    display: grid;
    text-align: center;
    margin-bottom: 10px;
    margin: auto;
}

.image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;

    @media (min-width: 768px) {
    }
    @media (min-width: 1024px) {
    }
}

.image > img {
    height: 130px;

    @media (min-width: 768px) {
        height: 115px;
    }
    @media (min-width: 1024px) {
        height: 150px;
    }
}
