@import '../../variables.scss';
@import '../../mixins.scss';

.simulador {
    padding: 10px $page-gap-mobile 50px $page-gap-mobile;

    @media (min-width:1900px) {
        padding: 10px $page-gap 50px $page-gap;
    }

    &__start_btn {
        display: grid;
        justify-content: center;

        & button {
           
            padding: 15px 0;
            background-color: $primary-color;
            border: none;
            text-transform: uppercase;
            letter-spacing: 0.5px;
            transition: 0.2s ease-in-out;
            border-radius: 4px;
            @include font-size(14);

            @media (min-width:1024px) {
                @include font-size(18);
            }
        }

        & button:hover {
            background-color: $secundary-color;
        }
    }

    &__perguntas {
        display: grid;
        gap: 20px;
        place-content: center;
    }

    &__row {
        display: grid;
        gap: 20px;

        @media (min-width:1024px) {
            grid-template-columns: 1.5fr 1fr;
        }

        @media (min-width:1440px) {
            grid-template-columns: 1.5fr 1fr 1fr;
        }
    }

    &__box {
        display: grid;
        gap: 15px;

        & fieldset {
            padding: 10px;
            border-radius: 4px;
            border-color: $light-color;
        }

        & legend {
            padding: 0 10px;
            font-weight: bold;

            & span {
                font-weight: bold;
                color: $danger-color;
            }
        }

        & ul {
            display: flex;
            flex-direction: column;
            gap: 5px;
        }

        & label {
            font-family: system-ui, sans-serif;
            display: grid;
            grid-template-columns: 1em auto;
            gap: 0.5em;
            padding: 5px 0;
            align-items: end;
        }
    }

    &__img {
        font-family: 'Source Sans Pro', sans-serif;
        background-image: url('../../assets/images/simulador_3.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: right;
        width: 100%;
        height: 300px;
        margin-bottom: 20px;
        position: relative;

        @media (min-width:1024px) {
            height: 350px;
        }

        @media (min-width:1900px) {
            height: 400px;
        }

        &::before {
            content: '';
            @include absolute(left 0 right 0 top 0 bottom 0);
            background-color: rgba(0, 0, 0, 0.6);
        }

        &_content {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -70%);
           
            color: $light-color;
            font-weight: bold;
            text-align: center;
            letter-spacing: 0.7px;
            
            @media (min-width:1024px) {
                top: 50%;
                transform: translate(-50%, -80%);
               
            }

            @media (min-width:1440px) {
                top: 50%;
                transform: translate(-50%, -80%);
               
            }

            & h2 {
                @include font-size(18);
                margin-bottom: 15px;
                
                @media (min-width:1024px) {
                    @include font-size(40);
                }

                @media (min-width:1440px) {
                    @include font-size(44)
                }
            }

            & p {
                @include font-size(14);

                @media (min-width:1024px) {
                    @include font-size(24);
                }

                @media (min-width:1440px) {
                    @include font-size(28);
                }
            }
        }
    }
}

.btns_acao {
    display: flex;
    gap: 20px;
    padding: 20px 0;

    & button {
       
        padding: 10px 0;
        border: none;
        border-radius: 4px;
        letter-spacing: 1px;
        @include font-size(18);

        &:first-child {
            background-color: $dark-color;
        }

        &:last-child {
            background-color: $primary-color;
        }

        &:disabled,
        &[disabled] {
            background-color: #ccc;
            color: $text-dark;
        }
    }
}

.planos {
    margin: 20px 0 50px 0;

    &__box {
        display: grid;
        gap: 20px;
    }

    & h3 {
        margin-bottom: 20px;
        color: $secundary-color;
        @include font-size(22);
        text-transform: uppercase;
        text-align: center;
        
        @media (min-width:1024px) {
            @include font-size(24);
        }
    }

    & h4 {
        color: $white-color;
        padding: 12px 15px;
        letter-spacing: 0.7px;
        background-color: $dark-color;
        @include font-size(16);
        text-transform: uppercase;
        
        @media (min-width:1024px) {
            @include font-size(18);
        }
    }

    & p,
    & ul {
        background-color: $light-color;
        @include font-size(14);
        padding: 18px 15px;
        letter-spacing: 0.5px;
        list-style-type: disclosure-closed;

        @media (min-width:1024px) {
            @include font-size(16);
        }
    }

    & ul {
        padding: 18px 15px 18px 40px;
    }

    & li {
        margin: 5px 0;
    }
}

.resultados {
    &__valores {
        & h3 {
            margin-bottom: 20px;
            color: $secundary-color;
            @include font-size(24);
            text-transform: uppercase;
        }

        & ul {
            @include font-size(18);
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        & span {
            @include font-size(18);
        }
    }

    &__legend {
        @include font-size(14);
    }

    &__desktop {
        display: none;
        width: 100%;
        height: 500px;

        @media (min-width:1024px) {
            display: block;
        }
    }

    &__mobile {
        display: block;
        width: 100%;
        height: 300px;

        @media (min-width:1024px) {
            display: none;
        }
    }
}
