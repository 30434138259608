@import '../../variables.scss';
@import '../../mixins.scss';

.tecno {
    padding: 2px $page-gap-mobile 5px $page-gap-mobile;

    @media (min-width: 1900px) {
        padding: 10px $page-gap 40px $page-gap;
    }

    &__logos {
        display: grid;
        gap: 23px;
        grid-template-columns: 1fr 1fr 1fr;

        @media (min-width: 768px) {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            gap:30px;
        }

        @media (min-width: 1024px) {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        }
    }

    &__logos img {
        height: 70px;
        width: 70px;
        
        @media (min-width:768px){
          width: 100px;
          height: 80px;
        }

        @media (min-width: 1024px) {
            height: 80px;
            margin: auto;
        }

        @media (min-width: 1900px) {
            height: 100px;
        }
    }
}


@mixin h3-max-width-580px {
    @media (max-width: 580px) {
        margin: 10px;
    }
}

.titulo > h3 {
    font-family: 'Nunito serif', sans-serif;
    font-weight: 700;
    font-size: 30px;
    text-align: center;
    margin: 10px 0;

    @media (min-width: 768px) {
     
    }

    @media (min-width: 1024px) {
        font-size:40px;

    }
}
