@import url('https://fonts.googleapis.com/css2?family=Borel&family=Nunito+Sans:opsz@6..12&family=Nunito:wght@400;700&family=REM:ital,wght@1,300&family=Roboto:wght@400;500;700&display=swap');

.titulo {
    font-family: 'Nunito', sans-serif;
    text-align: center;
    color:aliceblue;
    font-size: 25px;
    font-weight:bolder;
    margin: 5px;

    @media (min-width:768px){
        font-size: 32px;
    }

    @media (min-width:1024px){
            font-size: 38px;
    }
}