@import '../../variables.scss';
@import '../../mixins.scss';

.card {
    display: flex;
    justify-content: center;
}



@media (min-width:320px){
    .icon {
        width: 80px;
        height: 65px;
        stroke: #56bb8f;
        margin-right:10px ;
       
    }
}

@media (min-width:1024px){
    .icon {
        width: 32px;
        height: 32px;
        stroke: #56bb8f;
        margin-right: 15px;
        
    }
}

.icone {
    height:35px;
    width: 30px;
    stroke: #56bb8f;
    margin-right:10px ;
}

.text  {
    font-family: 'Nunito',sans-serif;
    font-weight: bold;
    margin:20px;
    color:#0F1628;

    @media (min-width:320px){
        margin-top: 70px;
        font-size: 12px;
        width: 270px;
        margin-left: -9px;
    }
    
    @media (min-width:768px){
        width: 100%;
        font-size: 18px;
    }
    @media(min-width: 1024px){
        font-size: 19px;
    }

    @media (min-width:1900px){
        font-size: 30px;
    }

}

.text > p {
    text-align: justify;
    color: #5F6368;
    font-size: 22px;
}

.elementos {
    position: relative;
    display: grid;
    text-align: center;
    gap: 10px;
    grid-template-columns: 1fr;
    box-shadow: -1px -1px 2px 1px  #0F1628;
    width: 1250px;
    padding: 15px;

    @media (min-width:320px){
        width:280px;
        margin: 20px;    
        margin-top: 50px;
    }

    @media (min-width:768px){
        margin-top: 0px;
        width: 600px;
    }


     @media (min-width:1024px){
         width: 1250px;
         margin-top: 20px;
         margin-left: 135px;
         }
     
     
     @media (min-width:1900px){
        margin-left: 0px;
         width: 1550px;
         margin-top: 20px;
         
    }
}

.lista {
    display:flex;
    text-align: start;
    align-items: center;

   
    @media (min-width:1024px){
        margin-left: 0px;
    }
    @media (min-width:1440px){

    }
    @media (min-width:1900px){

    }
}


.lista > p {
    display: flex;
    align-items: center;
    text-align: start;
    color:#5F6368;
    font-size: 14px;

    @media(min-width: 1024px){
        font-size: 20px;
    }
    @media (min-width:1900px){
        font-size: 26px;
    }
}


.media {
    position: absolute;
    top: 0;
    right: 0;

    @media (min-width:320px){
        display: inline-block;
        top: 0px;
        right: 80px;
        width: 95px;
        height: 82px;
        margin: 5px;

    }

    @media (min-width:768px){
          display: inline-block;
          margin-top: 35px;
          margin-right:20px ;
          width: 75px;
          height: 55px;
    }
    
    @media (min-width:1024px){
        margin-top:5px;
        margin-right: 0px;
        right: 0px;
        display: inline-block;
        width: 160px;
        height: 150px;
   
  }
   
  @media (min-width:1900px){
    margin-top:15px;
    margin-right: 10px;
    right: 0px;
    display: inline-block;
    width: 200px;
    height: 190px;

}
    
}