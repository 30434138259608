
@media (min-width:320px){
    .botao {
        display: grid;
        border: none;
        opacity: 1.0;
        text-align: center;
        font-size: 16px;
        font-weight: 800;
        border-radius: 10px;
        color:white;
        padding: 15px;
        background-color: #56BB8F;
    }
}
@media (min-width:768px){
    .botao {
        display: grid;
        border: none;
        opacity: 0.9;
        font-size: 20px;
        font-weight: 800;
        color:white;
        padding: 15px;

    }
}

.container_button {
    display: grid;
    grid-template-columns: 1fr;
    padding: 20px;
    @media(min-width:768px){
        padding: 0;
        margin-right: 0;
        margin:auto;
        margin-top: 30px;
        margin-bottom: 30px;
        width: 50%;
    }
    @media (min-width:1024px){
        width: 30%;
    }

}

.destaque{
    color:#0F1628;
    font-family: 'Nunito serif',sans-serif;
    font-weight: bolder;
}

.botao:hover {
    opacity: 0.8;
    transition: 1s;
}

.ancora {
    font-family: 'Nunito serif',sans-serif;
    font-weight: bolder;
    color:white;
}